import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IAffiliateShort } from '../../lib/types';
import { adminPagingAtom } from '../../recoil/admin-paging';
import { filteredSortedAffiliatesSelector } from '../../recoil/admin-sort-filter';
import './admin-paging.scss';

export default function AdminPaging(): JSX.Element {
   const backArrowSvg: URL = new URL(
      '/public/images/back-arrow.svg',
      import.meta.url
   );

   const affiliates: IAffiliateShort[] = useRecoilValue(
      filteredSortedAffiliatesSelector
   );

   const [currentPage, setCurrentPage] = useRecoilState(adminPagingAtom);
   const nextPage = () => {
      if ((currentPage + 1) * 25 < affiliates.length) {
         setCurrentPage(currentPage + 1);
      }
   };
   const prevPage = () => {
      if (currentPage > 0) {
         setCurrentPage(currentPage - 1);
      }
   };
   const firstPage = () => {
      setCurrentPage(0);
   };
   const lastPage = () => {
      setCurrentPage(Math.ceil(affiliates.length / 25) - 1);
   };

   return (
      <div className="admin-paging-control">
         <button
            className="tiny-button admin-paging-button"
            onClick={firstPage}
         >
            <img src={backArrowSvg.toString()} />
            <img src={backArrowSvg.toString()} />
         </button>
         <button className="tiny-button admin-paging-button" onClick={prevPage}>
            <img src={backArrowSvg.toString()} />
         </button>
         <div className="admin-paging-message body-small-regular">
            showing {Math.min(affiliates.length, currentPage * 25 + 1)}-
            {Math.min((currentPage + 1) * 25, affiliates.length)} of{' '}
            {affiliates.length}
         </div>
         <button className="tiny-button admin-paging-button" onClick={nextPage}>
            <img
               src={backArrowSvg.toString()}
               className="admin-paging-arrow-back"
            />
         </button>
         <button className="tiny-button admin-paging-button" onClick={lastPage}>
            <img
               src={backArrowSvg.toString()}
               className="admin-paging-arrow-back"
            />
            <img
               src={backArrowSvg.toString()}
               className="admin-paging-arrow-back"
            />
         </button>
      </div>
   );
}
