import React from 'react';
import { useNavigate } from 'react-router-dom';
import './search-dropdown-recent.scss';

export interface ISearchDropdownRecentProps {
   name: string;
   id: number;
   showId: boolean;
   resultPageLink: string;
}

export default function SearchDropdownRecent(
   props: ISearchDropdownRecentProps
): JSX.Element {
   const searchHoverSvg = new URL(
      '/public/images/search-hover.svg',
      import.meta.url
   );
   const navigate = useNavigate();

   function getDetails(): void {
      navigate(`/${props.resultPageLink}/${props.id}`);
   }

   return (
      <div className="search-dropdown-recent" onClick={() => getDetails()}>
         <img src={searchHoverSvg.toString()} />
         <div className="search-name h1-headline-light">{props.name}</div>
         <div className="search-recent h1-headline-light">{'(recent)'}</div>
         {props.showId && <div className="search-id-label h2-mono">ID</div>}
         {props.showId && <div className="search-id h2-mono">{props.id}</div>}
      </div>
   );
}
