import React from 'react';
import './secondary-button.scss';

export interface ISecondaryButtonProps {
   action: () => void;
   text: string;
   className?: string;
}

export default function SecondaryButton(
   props: ISecondaryButtonProps
): JSX.Element {
   return (
      <button
         className={`secondary-button ${
            props.className ? props.className : ''
         }`}
         onClick={() => props.action()}
      >
         <div className="body-standard-semibold">{props.text}</div>
      </button>
   );
}
