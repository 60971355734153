import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { adminAffiliatesSelector } from './admin';

// currently applied search
export const adminCitySearchQueryAtom = atom<string>({
   key: 'AdminCitySearchQuery',
   default: '',
});

// searches affiliates city list based on adminCitySearchQueryAtom
export const searchedCitySelector = selector({
   key: 'SearchedCityList',
   get: ({ get }): string[] => {
      const query = get(adminCitySearchQueryAtom);
      const affiliates = get(adminAffiliatesSelector);
      const searchResults: string[] = [];
      const lowercaseSet = new Set();

      const options = {
         includeScore: true,
         includeMatches: true,
         threshold: 0.5,
         keys: ['organizationCity'],
      };

      const fuse = new Fuse(affiliates, options);
      const results = fuse.search(query);

      for (const result of results.slice(0, 6)) {
         const lowercaseResult = result.item.organizationCity.toLowerCase();
         if (!lowercaseSet.has(lowercaseResult)) {
            lowercaseSet.add(lowercaseResult);
            searchResults.push(result.item.organizationCity);
         }
      }

      return searchResults;
   },
});

// currently applied city filters
export const adminCityFiltersAtom = atom<string[]>({
   key: 'AdminCityFilters',
   default: [],
});
