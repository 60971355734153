import { atom, selector } from 'recoil';
import api from '../lib/api';
import { getClaimEmail } from '../lib/authorization';
import { IAffiliateRecent } from '../lib/types';

export const affiliateRecentSelector = selector({
   key: 'affiliateRecentSelector',
   get: async (): Promise<IAffiliateRecent[]> => {
      const affiliateRecents: IAffiliateRecent[] | null =
         await api.getRecentAffiliates(getClaimEmail());
      if (!affiliateRecents) {
         return [];
      }
      return affiliateRecents;
   },
});

export const reloadRecentAffiliates = atom<boolean>({
   key: 'reloadRecentAffiliates',
   default: false,
});
