import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import './checkbox-text.scss';

export interface ICheckboxTextProps {
   text: string;
   checkboxState: RecoilState<boolean>;
}

export default function CheckboxText(props: ICheckboxTextProps): JSX.Element {
   const checkSvg = new URL('/public/images/check.svg', import.meta.url);
   const [checked, setChecked] = useRecoilState(props.checkboxState);

   const handleChange = (): void => {
      setChecked(!checked);
   };

   return (
      <label
         className={`checkbox-text ${checked ? 'checkbox-text-checked' : ''}`}
         htmlFor="checkbox"
      >
         <div className="body-standard-regular">{props.text}</div>
         <div className="checkbox-check">
            <img src={checkSvg.toString()} />
         </div>
         <input
            className="checkbox"
            id="checkbox"
            type="checkbox"
            onChange={handleChange}
            checked={checked}
         />
      </label>
   );
}
