import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import './filter-pill.scss';

export interface IFilterPillProps {
   text: string;
   currentFilters: RecoilState<string[]>;
}

export default function FilterPill(props: IFilterPillProps): JSX.Element {
   const clearSvg = new URL('/public/images/clear.svg', import.meta.url);

   const [currentFilters, setCurrentFilters] = useRecoilState(
      props.currentFilters
   );

   const handleChange = (): void => {
      const newFilters = currentFilters.filter((str) => str != props.text);
      setCurrentFilters(newFilters);
   };

   return (
      <div className="filter-pill" onClick={handleChange}>
         <div className="body-standard-regular">{props.text}</div>
         <img src={clearSvg.toString()} />
      </div>
   );
}
