import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import './filter-dropdown-item.scss';

export interface IFilterDropdownItemProps {
   text: string;
   filterAtom: RecoilState<string>;
   setChecked: (value: React.SetStateAction<boolean>) => void;
   setDropdownClass: (value: React.SetStateAction<string>) => void;
}

export default function FilterDropdownItem(
   props: IFilterDropdownItemProps
): JSX.Element {
   const [filter, setFilter] = useRecoilState(props.filterAtom);

   const handleChange = (newFilter): void => {
      setFilter(newFilter);
      props.setChecked(false);
      props.setDropdownClass('hidden');
   };

   return (
      <label
         className={`filter-dropdown-item body-large-regular ${
            filter == props.text
               ? 'filter-highlighted'
               : 'filter-not-highlighted'
         }`}
      >
         {props.text}
         <input
            className="checkbox"
            type="checkbox"
            onChange={() => handleChange(props.text)}
            checked={filter == props.text}
         />
      </label>
   );
}
