import { DateRange } from 'react-day-picker';
import { atom } from 'recoil';

// currently applied date range filter
// default to 30 days ago until today
// note: 30 days is equivalent to 2,592,000,000 milliseconds
export const dateRangeFilterAtom = atom<DateRange | undefined>({
   key: 'dateRangeFilter',
   default: {
      from: new Date(new Date().getTime() - 2592000000),
      to: new Date(),
   },
});
