import Cookies from 'js-cookie';
import { selector } from 'recoil';
import { IAffiliateImpersonation } from '../lib/types';

export const adminImpersonateCookie = selector({
   key: 'adminImpersonateCookieSelector',
   get: async (): Promise<IAffiliateImpersonation | null> => {
      const cookie = Cookies.get('x-affdash-actor');
      if (!cookie) {
         return null;
      }

      const parsedCookie: JSON = JSON.parse(cookie);
      const affiliateImpersonation: IAffiliateImpersonation = {
         affiliateId: parsedCookie['AffiliateId'],
         firstName: parsedCookie['FirstName'],
         lastName: parsedCookie['LastName'],
         email: parsedCookie['email'],
      };
      return affiliateImpersonation;
   },
});
