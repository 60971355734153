import { DateRange } from 'react-day-picker';
import { atom, selectorFamily } from 'recoil';
import { ICustomerDocument } from '../lib/types';
import { customerDocumentsAtomFamily } from './customer-documents';
import { dateRangeFilterAtom } from './document-date-filter';
import { jurisdictionFilterAtom } from './jurisdiction-filter';

// currently applied document filter
export const customerDocumentFilterAtom = atom<string>({
   key: 'CustomerDocumentListFilter',
   default: 'All Documents',
});

export const customerDocumentFiltersSelector = selectorFamily({
   key: 'CustomerDocumentFilters',
   get:
      (customerId) =>
      ({ get }): string[] => {
         const documents = get(customerDocumentsAtomFamily(customerId));
         const types = [
            'All Documents',
            ...new Set(documents.map((document) => document.type)),
         ];
         return types;
      },
});

// // filters customer documents list based on customersFilterAtom and jurisdictionFilterAtom
// export const filteredCustomerDocumentsSelector = selectorFamily({
//    key: 'FilteredCustomerDocumentList',
//    get:
//       (customerId) =>
//       ({ get }): ICustomerDocument[] => {
//          const filter = get(customerDocumentFilterAtom);
//          const jurisdiction = get(jurisdictionFilterAtom);
//          const date = get(dateRangeFilterAtom);
//          const documents = get(customerDocumentsAtomFamily(customerId));

//          let documentsJurisdiction = [...documents];
//          documentsJurisdiction = documentsJurisdiction.filter((document) =>
//             checkJurisdiction(document, jurisdiction)
//          );

//          let documentsDate = [...documentsJurisdiction];
//          documentsDate = documentsDate.filter((document) =>
//             checkDate(document, date)
//          );

//          if (filter == 'All Documents') {
//             return documentsDate;
//          }
//          return documentsDate.filter((document) => document.type == filter);
//       },
// });

export const filteredCustomerDocumentsSelector = selectorFamily({
   key: 'FilteredCustomerDocumentList',
   get:
      (customerId) =>
      ({ get }): ICustomerDocument[] => {
         //const filter = get(customerDocumentFilterAtom);
         //const jurisdiction = get(jurisdictionFilterAtom);
         const date = get(dateRangeFilterAtom);
         const documents = get(customerDocumentsAtomFamily(customerId)).filter(
            (document) => document.type == 'Summary Report'
         );

         let documentsDateFiltered = [...documents];
         documentsDateFiltered = documentsDateFiltered.filter((document) =>
            checkDate(document, date) == true
         );

         return documentsDateFiltered;
      },
});

function checkJurisdiction(
   document: ICustomerDocument,
   jurisdiction: string
): boolean {
   for (const documentMetadata of document.customerDocumentMetadata) {
      if (jurisdiction == 'All Jurisdictions') {
         return true;
      }
      if (
         documentMetadata.type == 'jurisdiction' &&
         documentMetadata.value == jurisdiction
      ) {
         return true;
      }
   }
   return false;
}

function checkDate(
   document: ICustomerDocument,
   date: DateRange | undefined
): boolean {
   const documentDate: Date = new Date(document.createdUtc);
   if (!date || !date.from || !date.to) return false;
   return documentDate >= date.from && documentDate <= date.to;
}
