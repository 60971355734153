import React, { useEffect, useRef } from 'react';
import { RecoilState, RecoilValueReadOnly, useRecoilState } from 'recoil';
import {
   IAffiliateRecent,
   IAffiliateShort,
   ICustomer,
   ICustomerRecent,
   ISearchResult,
} from '../../lib/types';
import ClearButton from '../clear-button/clear-button';
import SearchDropdown from '../search-dropdown/search-dropdown';
import './search.scss';

export interface ISearchProps {
   placeholderPrimary: string;
   placeholderSecondary: string;
   searchQueryAtom: RecoilState<string>;
   searchResultSelector: RecoilValueReadOnly<ISearchResult[]>;
   resultPageLink: string;
   searchById: boolean;
   disabled: boolean;
   customerRecents?: ICustomerRecent[];
   affiliateRecents?: IAffiliateRecent[];
   customers?: ICustomer[];
   affiliates?: IAffiliateShort[];
}

export default function Search(props: ISearchProps): JSX.Element {
   const [searchQuery, setSearchQuery] = useRecoilState(props.searchQueryAtom);
   const [dropdownClass, setDropdownClass] = React.useState(
      'search-dropdown-invisible'
   );
   const [placeholderClass, setPlaceholderClass] = React.useState('');

   const onFocus = (): void => {
      setDropdownClass('');
      setPlaceholderClass('placeholder-hidden');
   };

   const clearSearch = (): void => {
      setSearchQuery('');
   };

   useEffect(() => {
      clearSearch();
   }, []);

   const searchRef = useRef(null);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'search-input h1-headline-light'
            ) {
               setDropdownClass('search-dropdown-invisible');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }
   detectClick(searchRef);

   return (
      <div className="search">
         <div
            className={`search-bar ${
               searchQuery.length > 0 ? 'search-query-populated' : ''
            }`}
         >
            <div className="search-icon"></div>
            <input
               className="search-input h1-headline-light"
               type="text"
               placeholder=" "
               value={searchQuery}
               onFocus={onFocus}
               onBlur={() => setPlaceholderClass('')}
               onChange={(e) =>
                  setSearchQuery((e.target as HTMLInputElement).value)
               }
               disabled={props.disabled}
            />
            <div className={`placeholder-text ${placeholderClass}`}>
               <span className="placeholder-search h1-headline-light">
                  Search&nbsp;
               </span>
               <span className="placeholder-first h1-headline-light">
                  {props.placeholderPrimary}
               </span>
               <span className="placeholder-second h1-headline-light">
                  {props.placeholderSecondary}
               </span>
            </div>
            <ClearButton action={clearSearch} />
            {!props.disabled && (
               <SearchDropdown
                  class={dropdownClass}
                  searchById={props.searchById}
                  searchQueryAtom={props.searchQueryAtom}
                  searchResultSelector={props.searchResultSelector}
                  dropdownRef={searchRef}
                  resultPageLink={props.resultPageLink}
                  customerRecents={props.customerRecents}
                  affiliateRecents={props.affiliateRecents}
                  customers={props.customers}
                  affiliates={props.affiliates}
               />
            )}
         </div>
      </div>
   );
}
