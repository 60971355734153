import React from 'react';
import ClearButton from '../clear-button/clear-button';
import PrimaryButton from '../primary-button/primary-button';
import SecondaryButton from '../secondary-button/secondary-button';
import './modal.scss';

export interface IModalProps {
   headerText: string;
   bodyText: string;
   confirmText: string;
   modalClass: string;
   setModalClass: React.Dispatch<React.SetStateAction<string>>;
   confirmAction: () => void;
}

export default function Modal(props: IModalProps): JSX.Element {
   const confirmModal = (): void => {
      props.setModalClass('modal-hidden');
      props.confirmAction();
   };

   const hideModal = (): void => {
      props.setModalClass('modal-hidden');
   };

   return (
      <div className={`modal ${props.modalClass}`}>
         <div className="modal-wrapper">
            <div className="modal-nav-wrapper">
               <div className="body-standard-semibold">Attention</div>
               <ClearButton action={hideModal} />
            </div>
            <div className="modal-header h2-headline">{props.headerText}</div>
            <div className="modal-body body-large-regular">
               {props.bodyText}
            </div>
            <div className="modal-buttons-wrapper">
               <SecondaryButton action={hideModal} text="Cancel" />
               <PrimaryButton action={confirmModal} text={props.confirmText} />
            </div>
         </div>
      </div>
   );
}
