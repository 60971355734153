import React from 'react';
import { Filters, Sort } from '../../lib/types';
import { customersSortAtom } from '../../recoil/customers-sort-filter';
import QuickFilter from '../quick-filter/quick-filter';
import SortButton, { ISortButtonProps } from '../sort-button/sort-button';
import './sort-filter.scss';

export interface ISortFilterProps {
   disabled: boolean;
}

export default function SortFilter(props: ISortFilterProps): JSX.Element {
   const sortButtonProps: ISortButtonProps = {
      disabled: props.disabled,
      sortAtom: customersSortAtom,
      sorts: [
         { sort: Sort.firstName, ascendingDescending: true },
         { sort: Sort.lastName, ascendingDescending: true },
         { sort: Sort.email, ascendingDescending: false },
         { sort: Sort.mostRecentViolation, ascendingDescending: false },
         { sort: Sort.numberOfViolations, ascendingDescending: true },
         { sort: Sort.nextCalibrationDate, ascendingDescending: true },
         { sort: Sort.leaseDate, ascendingDescending: true },
         { sort: Sort.removalDate, ascendingDescending: true },
      ],
   };

   return (
      <div className="sort-filter">
         <div className="sort-filter-wrapper">
            <QuickFilter disabled={props.disabled} text={Filters.all} />
            <QuickFilter
               disabled={props.disabled}
               text={Filters.recentViolations}
            />
            <QuickFilter disabled={props.disabled} text={Filters.newClients} />
            <QuickFilter disabled={props.disabled} text={Filters.alerts} />
            <QuickFilter
               disabled={props.disabled}
               text={Filters.overdueCalibrations}
            />
            <SortButton
               disabled={sortButtonProps.disabled}
               sortAtom={customersSortAtom}
               sorts={sortButtonProps.sorts}
            />
         </div>
      </div>
   );
}
