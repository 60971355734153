import React, { useEffect, useRef } from 'react';
import { RecoilState, useRecoilValue } from 'recoil';
import FilterDropdownItem from '../filter-dropdown-item/filter-dropdown-item';
import './jurisdiction-dropdown.scss';

export interface IJurisdictionDropdownProps {
   filters: string[];
   filterAtom: RecoilState<string>;
}

export default function JurisdictionDropdown(
   props: IJurisdictionDropdownProps
): JSX.Element {
   const [checked, setChecked] = React.useState(false);
   const [dropdownClass, setDropdownClass] = React.useState('hidden');
   const filter: string = useRecoilValue(props.filterAtom);

   const handleChange = () => {
      setChecked(!checked);
      checked ? setDropdownClass('hidden') : setDropdownClass('');
   };

   const wrapperRef = useRef(null);
   detectClick(wrapperRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'jurisdiction-dropdown' &&
               event.target.className !=
                  'jurisdiction-dropdown-text body-standard-semibold' &&
               event.target.className != 'jurisdiction-dropdown-icon'
            ) {
               setChecked(false);
               setDropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <label
         className={`jurisdiction-dropdown ${
            checked ? 'jurisdiction-dropdown-checked' : ''
         }`}
      >
         <div className="jurisdiction-dropdown-text body-standard-semibold">
            {filter}
         </div>
         <div className="jurisdiction-dropdown-icon" />
         <input
            className="jurisdiction-checkbox"
            type="checkbox"
            onChange={handleChange}
            checked={checked}
         />
         <div
            ref={wrapperRef}
            className={`jurisdiction-dropdown-dropdown ${dropdownClass}`}
         >
            {props.filters.map((filter, index) => (
               <FilterDropdownItem
                  text={filter}
                  filterAtom={props.filterAtom}
                  key={index}
                  setChecked={setChecked}
                  setDropdownClass={setDropdownClass}
               />
            ))}
         </div>
      </label>
   );
}
