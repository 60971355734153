import React from 'react';
import { RecoilState, RecoilValueReadOnly, useRecoilValue } from 'recoil';
import {
   IAffiliateRecent,
   IAffiliateShort,
   ICustomer,
   ICustomerRecent,
   ISearchResult,
} from '../../lib/types';
import SearchDropdownItem from '../search-dropdown-item/search-dropdown-item';
import SearchDropdownRecent from '../search-dropdown-recent/search-dropdown-recent';
import './search-dropdown.scss';

export interface ISearchDropdownProps {
   class: string;
   searchQueryAtom: RecoilState<string>;
   searchResultSelector: RecoilValueReadOnly<ISearchResult[]>;
   searchById: boolean;
   dropdownRef: React.MutableRefObject<null>;
   resultPageLink: string;
   customerRecents?: ICustomerRecent[];
   affiliateRecents?: IAffiliateRecent[];
   customers?: ICustomer[];
   affiliates?: IAffiliateShort[];
}

export default function SearchDropdown(
   props: ISearchDropdownProps
): JSX.Element {
   const searchTerm: string = useRecoilValue(props.searchQueryAtom);
   const results: ISearchResult[] = useRecoilValue(props.searchResultSelector);

   const getCustomerNameById = (customerId: number): string => {
      if (!props.customers) {
         return '';
      }
      const customer: ICustomer | undefined = props.customers.find(
         (c) => c.id === customerId
      );
      return customer ? customer.firstName + ' ' + customer.lastName : '';
   };

   const getAffiliateNameById = (affiliateId: number): string => {
      if (!props.affiliates) {
         return '';
      }
      const affiliate: IAffiliateShort | undefined = props.affiliates.find(
         (a) => a.id === affiliateId
      );
      return affiliate ? affiliate.firstName + ' ' + affiliate.lastName : '';
   };

   return (
      <div
         className={`search-dropdown ${props.class} ${
            ((props.customerRecents && props.customerRecents.length == 0) ||
               (props.affiliateRecents &&
                  props.affiliateRecents.length == 0)) &&
            results.length == 0
               ? 'search-dropdown-invisible'
               : ''
         }`}
         ref={props.dropdownRef}
      >
         {searchTerm.length > 0 ? (
            <div className="search-dropdown-items">
               <SearchDropdownItem
                  index={0}
                  searchResultSelector={props.searchResultSelector}
                  searchById={props.searchById}
                  resultPageLink={props.resultPageLink}
               />
               <SearchDropdownItem
                  index={1}
                  searchResultSelector={props.searchResultSelector}
                  searchById={props.searchById}
                  resultPageLink={props.resultPageLink}
               />
               <SearchDropdownItem
                  index={2}
                  searchResultSelector={props.searchResultSelector}
                  searchById={props.searchById}
                  resultPageLink={props.resultPageLink}
               />
               <SearchDropdownItem
                  index={3}
                  searchResultSelector={props.searchResultSelector}
                  searchById={props.searchById}
                  resultPageLink={props.resultPageLink}
               />
            </div>
         ) : (
            <div className="search-dropdown-items">
               {props.customerRecents &&
                  props.customerRecents
                     .slice(0)
                     .reverse()
                     .map((cr) => (
                        <SearchDropdownRecent
                           key={cr.customerId}
                           name={getCustomerNameById(cr.customerId)}
                           id={cr.customerId}
                           showId={true}
                           resultPageLink={props.resultPageLink}
                        />
                     ))}
               {!props.customerRecents &&
                  props.affiliateRecents &&
                  props.affiliateRecents
                     .slice(0)
                     .reverse()
                     .map((ar) => (
                        <SearchDropdownRecent
                           key={ar.affiliateId}
                           name={getAffiliateNameById(ar.affiliateId)}
                           id={ar.affiliateId}
                           showId={false}
                           resultPageLink={props.resultPageLink}
                        />
                     ))}
            </div>
         )}
         {results.length >= 4 && (
            <div className="search-dropdown-info body-small-regular">
               Search is limited to four results, type more to refine.
            </div>
         )}
      </div>
   );
}
