import React from 'react';
import { getClaimEmail, getClaimName } from '../../lib/authorization';
import './admin-profile-content.scss';

export default function AdminProfileContent(): JSX.Element {
   const emailDisabledSvg: URL = new URL(
      '/public/images/email-disabled.svg',
      import.meta.url
   );

   return (
      <div className="admin-profile-content">
         <div className="admin-label body-standard-regular">Admin</div>
         <div className="admin-name h1-headline-regular">{getClaimName()}</div>
         <div className="personal-label label-xsmall-allcaps">personal</div>
         <div className="email-wrapper">
            <img src={emailDisabledSvg.toString()} />
            <div className="profile-email body-standard-regular">
               {getClaimEmail()}
            </div>
         </div>
      </div>
   );
}
