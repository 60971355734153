import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import appConfig from './app-config';

class Insights {
   enableInsights(): void {
      const appInsights: ApplicationInsights = new ApplicationInsights({
         config: {
            connectionString: appConfig.appInsights.connectionString,
            /* ...Other Configuration Options... */
         },
      });

      appInsights.loadAppInsights();
      appInsights.trackPageView();
   }
}
export default new Insights();
