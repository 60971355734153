import React from 'react';
import './primary-button.scss';

export interface IPrimaryButtonProps {
   action: () => void;
   text: string;
   className?: string;
}

export default function PrimaryButton(props: IPrimaryButtonProps): JSX.Element {
   return (
      <button
         className={`primary-button ${props.className ? props.className : ''}`}
         onClick={() => props.action()}
      >
         <div className="body-large-semibold background-white">
            {props.text}
         </div>
      </button>
   );
}
