import { EventType } from '@azure/msal-browser';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import PrimaryButton from '../components/primary-button/primary-button';
import WelcomeContent from '../components/welcome-content/welcome-content';
import WelcomeLogos from '../components/welcome-logos/welcome-logos';
import { login } from '../lib/authorization';
import { adClient } from '../lib/http-client';
import './welcome.scss';

export default function Welcome(): JSX.Element {
   const navigate = useNavigate();

   useEffect(() => {
      const callbackId = adClient.addEventCallback((event) => {
         if (event.eventType == EventType.LOGIN_SUCCESS) {
            navigate('/postlogin');
         }
      });
      return () => {
         if (callbackId) {
            adClient.removeEventCallback(callbackId);
         }
      };
   }, [adClient]);

   const loggedIn = (): boolean => {
      const accounts = adClient.getAllAccounts();
      return accounts && accounts.length > 0;
   };

   const navigatePostlogin = (): void => {
      navigate('/postlogin');
   };

   const welcomeLogin = (): void => {
      login('redirect');
   };

   return (
      <div className="welcome">
         <div className="app-body">
            <AppHeader overrideName={false} />
            <div className="welcome-content">
               <WelcomeContent />
            </div>
            {loggedIn() ? (
               <PrimaryButton
                  className="button-link-create-account"
                  text="Get Started"
                  action={navigatePostlogin}
               />
            ) : (
               <PrimaryButton
                  className="button-link-create-account"
                  text="Create an Account"
                  action={welcomeLogin}
               />
            )}
            <div className="image-background">
               <div className="preview"></div>
            </div>
         </div>
         <WelcomeLogos />
         <AppFooter />
      </div>
   );
}
