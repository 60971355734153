import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { adminAffiliatesSelector } from './admin';

// currently applied search
export const adminFirmSearchQueryAtom = atom<string>({
   key: 'AdminFirmSearchQuery',
   default: '',
});

// searches affiliates firm list based on adminFirmSearchQueryAtom
export const searchedFirmSelector = selector({
   key: 'SearchedFirmList',
   get: ({ get }): string[] => {
      const query = get(adminFirmSearchQueryAtom);
      const affiliates = get(adminAffiliatesSelector);
      const searchResults: string[] = [];
      const lowercaseSet = new Set();

      const options = {
         includeScore: true,
         includeMatches: true,
         threshold: 0.5,
         keys: ['organizationName'],
      };

      const fuse = new Fuse(affiliates, options);
      const results = fuse.search(query);

      for (const result of results.slice(0, 6)) {
         const lowercaseResult = result.item.organizationName.toLowerCase();
         if (!lowercaseSet.has(lowercaseResult)) {
            lowercaseSet.add(lowercaseResult);
            searchResults.push(result.item.organizationName);
         }
      }

      return searchResults;
   },
});

// currently applied firm filters
export const adminFirmFiltersAtom = atom<string[]>({
   key: 'AdminFirmFilters',
   default: [],
});
