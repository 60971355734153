import React from 'react';
import { RecoilState, useRecoilState, useSetRecoilState } from 'recoil';
import { adminPagingAtom } from '../../recoil/admin-paging';
import './filter-search-item.scss';

export interface IFilterSearchItemProps {
   text: string;
   searchQueryAtom: RecoilState<string>;
   filterAtom: RecoilState<string[]>;
   setDropdownClass: (value: React.SetStateAction<string>) => void;
}

export default function FilterSearchItem(
   props: IFilterSearchItemProps
): JSX.Element {
   const [filters, setFilters] = useRecoilState(props.filterAtom);
   const setSearchQuery = useSetRecoilState(props.searchQueryAtom);
   const setCurrentPage = useSetRecoilState(adminPagingAtom);

   const handleChange = (): void => {
      if (filters.indexOf(props.text) == -1) {
         setFilters([...filters, props.text]);
      }
      props.setDropdownClass('hidden');
      setSearchQuery('');
      setCurrentPage(0);
   };

   return (
      <div
         className={`filter-search-item body-large-regular
         }`}
         onClick={handleChange}
      >
         {props.text}
      </div>
   );
}
