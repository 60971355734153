import { atom } from 'recoil';

type DocumentUrlCacheInfo = {
   documentId: number;
   documentBlobUrl: string;
   onEvict: () => void;
};

export const documentUrlCacheAtom = atom<DocumentUrlCacheInfo[]>({
   key: 'documentUrlCache',
   default: [],
});
