import React from 'react';
import { useRecoilValue } from 'recoil';
import { ICustomer } from '../../lib/types';
import {
   customerJurisdictionSelector,
   jurisdictionFilterAtom,
} from '../../recoil/jurisdiction-filter';
import { assignBadges } from '../card-customer/card-customer';
import ContactButton from '../contact-button/contact-button';
import EmailButton from '../email-button/email-button';
import JurisdictionDropdown, {
   IJurisdictionDropdownProps,
} from '../jurisdiction-dropdown/jurisdiction-dropdown';
import LocationButton from '../location-button/location-button';
import './customer-details-header.scss';

export interface ICustomerDetailsHeaderProps {
   customer: ICustomer;
}

export default function CustomerDetailsHeader(
   props: ICustomerDetailsHeaderProps
): JSX.Element {
   const idSeparatorSvg = new URL(
      '/public/images/id-separator.svg',
      import.meta.url
   );

   const jurisdictions: string[] = useRecoilValue(
      customerJurisdictionSelector(props.customer.id)
      //customerReportingStatesAtomFamily(props.customer.id)
      //customerDocumentJurisdictionSelector(props.customer.id)
   );

   const dropdownFilterProps: IJurisdictionDropdownProps = {
      filters: jurisdictions,
      filterAtom: jurisdictionFilterAtom,
   };

   const customerStreet: string =
      props.customer.address +
      (props.customer.address2 != null ? ' ' + props.customer.address2 : '');
   const customerAddress: string =
      props.customer.city +
      ', ' +
      props.customer.state +
      ' ' +
      props.customer.zip;

   return (
      <div className="customer-details-header">
         <div className="customer-details-text">
            <div className="customer-name h1-headline-regular">
               {props.customer.firstName} {props.customer.lastName}
            </div>
            <div className="customer-id">
               <span className="id-label body-standard-regular">ID</span>
               <img src={idSeparatorSvg.toString()} />
               <span className="id-number label-mono">
                  #{props.customer.id}
               </span>
            </div>
            {assignBadges(props.customer)}
         </div>
         <div className="customer-details-interactive">
            <div className="customer-details-contact">
               <ContactButton disabled={false} contact={props.customer.phone} />
               <LocationButton
                  disabled={false}
                  location1={customerStreet}
                  location2={customerAddress}
               />
               <EmailButton disabled={false} email={props.customer.email} />
            </div>
            <div className="state-jurisdiction-wrapper">
               <div className="state-jurisdiction-label label-small-allcaps primary-dark-3">
                  state of jurisdiction
               </div>
               <JurisdictionDropdown
                  filters={dropdownFilterProps.filters}
                  filterAtom={dropdownFilterProps.filterAtom}
               />
            </div>
         </div>
      </div>
   );
}
