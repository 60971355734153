import React, { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import AffiliateAttorney from '../components/affiliate-attorney/affiliate-attorney';
import AffiliateUnverified from '../components/affiliate-unverified/affiliate-unverified';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import ImpersonateHeader from '../components/impersonate-header/impersonate-header';
import { IAffiliate } from '../lib/types';
import { affiliateAtom } from '../recoil/affiliate';
import './affiliate.scss';

export default function AffiliatePage(): JSX.Element {
   const affiliate: IAffiliate | null = useRecoilValue(affiliateAtom);
   const view = 'attorney'; // for future view expansion

   let content: ReactElement;
   if (
      !affiliate ||
      (affiliate && (affiliate.vetted == '0' || affiliate.deactivated))
   ) {
      content = <AffiliateUnverified />;
   } else {
      if (view == 'attorney') {
         content = <AffiliateAttorney />;
      } else if (view == 'probation-officer') {
         content = <div>Probation Officer View</div>;
      } else if (view == 'prosecutor') {
         content = <div>Prosecutor View</div>;
      } else {
         throw new Error(`Unrecognized view: '${view}'`);
      }
   }

   return (
      <div className="affiliate">
         <div className="app-body">
            <ImpersonateHeader />
            <AppHeader overrideName={true} />
            {content}
         </div>
         <AppFooter />
      </div>
   );
}
