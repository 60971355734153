import { useMsal } from '@azure/msal-react';
import React from 'react';

export type LogoutButtonProps = {
   logoutType: 'popup' | 'redirect';
};

// This is very rudimentary for now.
export function LogoutButton({ logoutType }: LogoutButtonProps) {
   const { instance: adInstance } = useMsal();
   const onLogout = () => {
      if (logoutType === 'popup') {
         adInstance.logoutPopup({
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/', // redirects the top level app after logout
         });
      } else if (logoutType === 'redirect') {
         adInstance.logoutRedirect({
            postLogoutRedirectUri: '/',
         });
      }
   };

   // TODO: make me look nicer
   return <input type="button" onClick={onLogout} value="Log Out" />;
}
