import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import api from '../../lib/api';
import {
   IAffiliate,
   IAffiliateDeactivated,
   IAffiliateShort,
   IImpersonationRequest,
} from '../../lib/types';
import { adminAffiliatesAtom } from '../../recoil/admin';
import ContactButton from '../contact-button/contact-button';
import DeactivateAffiliateButton from '../deactivate-affiliate-button/deactivate-affiliate-button';
import EmailButton from '../email-button/email-button';
import LocationButton from '../location-button/location-button';
import Modal, { IModalProps } from '../modal/modal';
import TinyButton from '../tiny-button/tiny-button';
import './affiliate-details-header.scss';

export interface IAffiliateDetailsHeaderProps {
   affiliate: IAffiliate;
}

export default function AffiliateDetailsHeader(
   props: IAffiliateDetailsHeaderProps
): JSX.Element {
   const unverifiedBadgeSvg = new URL(
      '/public/images/unverified-badge.svg',
      import.meta.url
   );
   const ellipse3Svg: URL = new URL(
      '/public/images/ellipse-3.svg',
      import.meta.url
   );

   const navigate = useNavigate();

   const affiliateStreet: string =
      props.affiliate.address +
      (props.affiliate.address2 != null ? ' ' + props.affiliate.address2 : '');
   const affiliateAddress: string =
      props.affiliate.city +
      ', ' +
      props.affiliate.state +
      ' ' +
      props.affiliate.zip;

   const affiliateDeactivatedTrue: IAffiliateDeactivated = {
      affiliateId: props.affiliate.id,
      deactivated: true,
   };

   const affiliateDeactivatedFalse: IAffiliateDeactivated = {
      affiliateId: props.affiliate.id,
      deactivated: false,
   };

   const deactivateAffiliate = (
      affiliate: IAffiliate,
      affiliateDeactivated: IAffiliateDeactivated
   ): void => {
      api.putDeactivateAffiliate(affiliateDeactivated);

      const newAffiliateList: IAffiliateShort[] = [...affiliates].map((a) => {
         if (a.id === affiliate.id)
            return { ...a, deactivated: affiliateDeactivated.deactivated };
         else return a;
      });
      setAffiliates(newAffiliateList);
   };

   const modalConfirm = (): void => {
      deactivateAffiliate(props.affiliate, affiliateDeactivatedTrue);
   };

   const impersonateAffiliate = () => {
      navigate('/affiliate');
      impersonateAffiliateRequest(props.affiliate.id);
   };

   const [modalClass, setModalClass] = React.useState('modal-hidden');
   const modalProps: IModalProps = {
      headerText:
         'This affiliate will no longer be able to access the information for their clients.',
      bodyText:
         'We will retain client records and the client will still be able to request access to their records. For information on moving the existing clients to a new or different account, please contact partner@intoxalock.com with the new attorney name, firm name, client name(s), and phone number(s).',
      confirmText: 'Yes, deactivate',
      modalClass: modalClass,
      setModalClass: setModalClass,
      confirmAction: modalConfirm,
   };
   const [affiliates, setAffiliates] = useRecoilState(adminAffiliatesAtom);

   const showModal = (): void => {
      setModalClass('');
   };

   return (
      <div className="affiliate-details-header">
         <div className="affiliate-details-text">
            {props.affiliate.deactivated && (
               <div className="affiliate-details-unverified-label label-small-allcaps">
                  <img src={unverifiedBadgeSvg.toString()} />
                  deactivated
               </div>
            )}
            {props.affiliate.vetted ? (
               <div className="affiliate-type body-standard-regular">
                  {props.affiliate.title}
               </div>
            ) : (
               <div className="affiliate-details-unverified-label label-small-allcaps">
                  <img src={unverifiedBadgeSvg.toString()} />
                  unverified
               </div>
            )}
            <div className="affiliate-name h1-headline-regular">
               {props.affiliate.firstName} {props.affiliate.lastName}
            </div>
            <div className="affiliate-firm body-standard-regular">
               {props.affiliate.vetted
                  ? props.affiliate.organizationName
                  : '-----'}
            </div>
            <div className="affiliate-location-wrapper">
               <div className="affiliate-firm-city body-small-regular">
                  {props.affiliate.vetted
                     ? props.affiliate.organizationCity
                     : '----'}
               </div>
               <img src={ellipse3Svg.toString()} />
               <div className="affiliate-firm-state body-small-regular">
                  {props.affiliate.vetted
                     ? props.affiliate.organizationState
                     : '----'}
               </div>
            </div>
         </div>
         <div className="affiliate-details-interactive">
            <div className="affiliate-details-contact">
               <ContactButton
                  disabled={!props.affiliate.vetted}
                  contact={props.affiliate.phone}
               />
               <LocationButton
                  disabled={!props.affiliate.vetted}
                  location1={affiliateStreet}
                  location2={affiliateAddress}
               />
               <EmailButton
                  disabled={!props.affiliate.vetted}
                  email={props.affiliate.email}
               />
            </div>
            <div className="affiliate-details-buttons">
               {props.affiliate.deactivated ? (
                  <DeactivateAffiliateButton
                     deactivate={deactivateAffiliate}
                     affiliate={props.affiliate}
                     affiliateDeactivated={affiliateDeactivatedFalse}
                     disabled={!props.affiliate.vetted}
                     text="Activate"
                  />
               ) : (
                  <TinyButton
                     action={showModal}
                     disabled={!props.affiliate.vetted}
                     text="Deactivate"
                  />
               )}
               <TinyButton
                  action={impersonateAffiliate}
                  disabled={false}
                  text="Impersonate"
               />
            </div>
            <Modal
               headerText={modalProps.headerText}
               bodyText={modalProps.bodyText}
               confirmText={modalProps.confirmText}
               modalClass={modalProps.modalClass}
               setModalClass={modalProps.setModalClass}
               confirmAction={modalProps.confirmAction}
            />
         </div>
      </div>
   );
}

async function impersonateAffiliateRequest(affiliateId): Promise<void> {
   const impersonationRequest: IImpersonationRequest = {
      affiliateId: affiliateId,
   };
   await api.postImpersonateAffiliate(impersonationRequest);
   window.location.reload();
}
