import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { adminAffiliatesSelector } from './admin';

// currently applied search
export const adminStateSearchQueryAtom = atom<string>({
   key: 'AdminStateSearchQuery',
   default: '',
});

// searches affiliates state list based on adminStateSearchQueryAtom
export const searchedStateSelector = selector({
   key: 'SearchedStateList',
   get: ({ get }): string[] => {
      const query = get(adminStateSearchQueryAtom);
      const affiliates = get(adminAffiliatesSelector);
      const searchResults: string[] = [];
      const lowercaseSet = new Set();

      const options = {
         includeScore: true,
         includeMatches: true,
         threshold: 0.5,
         keys: ['organizationState'],
      };

      const fuse = new Fuse(affiliates, options);
      const results = fuse.search(query);

      for (const result of results.slice(0, 6)) {
         const lowercaseResult = result.item.organizationState.toLowerCase();
         if (!lowercaseSet.has(lowercaseResult)) {
            lowercaseSet.add(lowercaseResult);
            searchResults.push(result.item.organizationState);
         }
      }

      return searchResults;
   },
});

// currently applied state filters
export const adminStateFiltersAtom = atom<string[]>({
   key: 'AdminStateFilters',
   default: [],
});
