import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { ISearchResult } from '../lib/types';
import { archivedCustomersSelector } from './customers-sort-filter';

// currently applied search
export const customersSearchQueryAtom = atom<string>({
   key: 'CustomerListSearchQuery',
   default: '',
});

// searches customers list based on customersSearchQueryAtom
export const searchedCustomersSelector = selector({
   key: 'SearchedCustomerList',
   get: ({ get }): ISearchResult[] => {
      const query = get(customersSearchQueryAtom);
      const customers = get(archivedCustomersSelector);
      const searchResults: ISearchResult[] = [];

      const options = {
         includeScore: true,
         includeMatches: true,
         threshold: 0.5,
         keys: ['name', 'id'],
      };

      const fuse = new Fuse(customers, options);
      const results = fuse.search(query);

      for (const result of results) {
         searchResults.push({
            entity: result.item,
            key: result.matches?.[0].key,
            indices: result.matches?.[0].indices,
         });
      }

      return searchResults;
   },
});
