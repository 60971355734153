import React from 'react';
import { IAffiliate, ICustomerDocument } from '../../lib/types';
import DownloadButton from '../download-button/download-button';
import './date-and-document.scss';
import api from '../../lib/api';
import { useRecoilValue } from 'recoil';
import { affiliateAtom } from '../../recoil/affiliate';

export interface IDateAndDocumentProps {
   text: string;
   customerDocument: ICustomerDocument;
}

export default function DateAndDocument(
   props: IDateAndDocumentProps
): JSX.Element {
   const ellipse3Svg = new URL('/public/images/ellipse-3.svg', import.meta.url);
   const date: Date = new Date(props.customerDocument.createdUtc);
   const month: string = date.toLocaleString('default', { month: 'long' });
   const day: number = date.getUTCDate();
   const year: number = date.getUTCFullYear();

   return (
      <div className="date-and-document">
         <div className="date-and-document-date label-small-allcaps">
            {month + ' ' + day + ', ' + year}
         </div>
         <div className="date-and-document-body">
            <div className="date-and-document-text">
               <div className="body-large-semibold document-summary">
                  {props.text}
               </div>
               <div className="document-info">
                  <div className="body-small-regular document-type">
                     {props.customerDocument.title}
                  </div>
                  {props.customerDocument.size && (
                     <img src={ellipse3Svg.toString()} />
                  )}
                  {props.customerDocument.size && (
                     <div className="document-size body-small-regular">
                        {props.customerDocument.size} KB
                     </div>
                  )}
               </div>
            </div>
            <DownloadButton doc={props.customerDocument} inverted={true} />
         </div>
      </div>
   );
}
