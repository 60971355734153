import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getClaimFirstName, isAffiliate, login } from '../../lib/authorization';
import { adClient } from '../../lib/http-client';
import { IAffiliateImpersonation } from '../../lib/types';
import { adminImpersonateCookie } from '../../recoil/admin-impersonate';
import HelpModal from '../help-modal/help-modal';
import './app-header.scss';

export interface IAppHeaderProps {
   overrideName: boolean;
}

export default function AppHeader(props: IAppHeaderProps): JSX.Element {
   const navigate = useNavigate();
   const profileSvg = new URL('/public/images/profile.svg', import.meta.url);
   const [modalClass, setModalClass] = React.useState('modal-hidden');

   const loggedIn = (): boolean => {
      const accounts = adClient.getAllAccounts();
      return accounts && accounts.length > 0;
   };

   const showModal = (): void => {
      setModalClass('');
   };

   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );
   const getProfileName = (): string => {
      if (props.overrideName && cookie) {
         return 'Hi, ' + cookie.firstName;
      }
      const name: string = getClaimFirstName();
      if (name.length > 0) {
         return 'Hi, ' + name;
      }
      return 'Profile';
   };

   return (
      <nav className="app-header">
         <div className="logo" onClick={() => navigate('/postlogin')}></div>
         <div className="nav-buttons">
            {isAffiliate() && (
               <div className="help-button" onClick={() => showModal()}>
                  <div className="body-standard-semibold">Help</div>
               </div>
            )}
            <HelpModal modalClass={modalClass} setModalClass={setModalClass} />
            {loggedIn() ? (
               <Link className="nav-link-profile" to="/profile">
                  <div className="profile-icon">
                     <img src={profileSvg.toString()} />
                  </div>
                  <div className="body-standard-semibold">
                     {getProfileName()}
                  </div>
               </Link>
            ) : (
               <div
                  className="nav-link-login"
                  onClick={() => login('redirect')}
               >
                  <div className="profile-icon">
                     <img src={profileSvg.toString()} />
                  </div>
                  <div className="body-standard-semibold">Log in</div>
               </div>
            )}
         </div>
      </nav>
   );
}
