import React, { useEffect, useRef } from 'react';
import CopyButton from '../copy-button/copy-button';
import './email-button.scss';

export interface IEmailButtonProps {
   disabled: boolean;
   email: string;
}

export default function EmailButton(props: IEmailButtonProps): JSX.Element {
   const [checked, setChecked] = React.useState(false);
   const [dropdownClass, setdropdownClass] = React.useState('hidden');

   const handleChange = (): void => {
      setChecked(!checked);
      checked ? setdropdownClass('hidden') : setdropdownClass('');
   };

   const contactRef = useRef(null);
   detectClick(contactRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'email-button-icon' &&
               event.target.className != 'email-button' &&
               event.target.className != 'email-button email-checked ' &&
               event.target.className !=
                  'email-button-dropdown-email body-standard-regular' &&
               event.target.className != 'email-button-dropdown'
            ) {
               setChecked(false);
               setdropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <label
         className={`email-button ${checked ? 'email-checked' : ''} ${
            props.disabled ? 'email-disabled' : ''
         }`}
      >
         <input
            className="email-checkbox"
            type="checkbox"
            onChange={handleChange}
            disabled={props.disabled}
            checked={checked}
         />
         <div className="email-button-icon"></div>
         <div
            ref={contactRef}
            onClick={(e) => e.preventDefault()}
            className={`email-button-dropdown ${dropdownClass}`}
         >
            <div className="email-button-dropdown-email body-standard-regular">
               {!props.email || props.email.length <= 1 ? 'N/A' : props.email}
            </div>
            <CopyButton copyText={props.email} />
         </div>
      </label>
   );
}
