import {
   SerializableParam,
   atom,
   atomFamily,
   selector,
   selectorFamily,
} from 'recoil';
import api from '../lib/api';
import { IAffiliate, IAffiliateShort, ICustomer } from '../lib/types';

export const adminAffiliatesSelector = selector({
   key: 'adminAffiliatesSelector',
   get: async (): Promise<IAffiliateShort[]> => {
      const affiliates: IAffiliateShort[] | null =
         await api.getAdminAffiliates();

      for (const affiliate in affiliates) {
         affiliates[affiliate].name =
            affiliates[affiliate].firstName +
            ' ' +
            affiliates[affiliate].lastName;
      }
      return affiliates ? affiliates : [];
   },
});

export const adminAffiliatesAtom = atom<IAffiliateShort[]>({
   key: 'adminAffiliatesAtom',
   default: adminAffiliatesSelector,
});

export const adminAffiliateAtomFamily = atomFamily<
   IAffiliate | null,
   SerializableParam
>({
   key: 'adminAffiliateAtomFamily',
   default: selectorFamily({
      key: 'adminAffiliateSelector',
      get: (affiliateId) => async () => {
         const affiliate = await api.getAdminAffiliate(Number(affiliateId));
         return affiliate;
      },
   }),
});

export const adminAffiliateCustomersAtomFamily = atomFamily<
   ICustomer[],
   SerializableParam
>({
   key: 'adminAffiliateCustomersAtom',
   default: selectorFamily({
      key: 'adminAffiliateCustomersSelector',
      get: (affiliateId) => async () => {
         const customers = await api.getAdminAffiliateCustomers(
            Number(affiliateId)
         );
         return customers != null ? customers : [];
      },
   }),
});
