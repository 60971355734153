import React, { useRef } from 'react';
import './modal-textbox.scss';

export interface IModalTextboxProps {
   labelText: string;
   textState: string;
   maxLength: number;
   setTextState: React.Dispatch<React.SetStateAction<string>>;
}

export default function ModalTextbox(props: IModalTextboxProps): JSX.Element {
   const [textboxClass, setTextboxClass] = React.useState('');
   const inputRef = useRef<HTMLInputElement>(null);

   return (
      <div
         className={`modal-textbox ${textboxClass}`}
         onClick={() => {
            if (inputRef.current) inputRef.current.focus();
         }}
      >
         <div className="modal-textbox-label body-small-regular">
            {props.labelText}
            <span className="modal-textbox-required body-small-regular">
               {' '}
               *
            </span>
         </div>

         <input
            className="modal-textbox-input body-large-regular"
            maxLength={props.maxLength}
            onFocus={() => setTextboxClass('modal-textbox-focused')}
            onBlur={() => setTextboxClass('')}
            onChange={(e) =>
               props.setTextState((e.target as HTMLInputElement).value)
            }
            value={props.textState}
            ref={inputRef}
         />
      </div>
   );
}
