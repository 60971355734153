import React from 'react';
import './tiny-button.scss';

export interface ITinyButtonProps {
   action: () => void;
   disabled: boolean;
   text: string;
}

export default function TinyButton(props: ITinyButtonProps): JSX.Element {
   return (
      <button
         className={`tiny-button ${
            props.disabled ? 'tiny-button-disabled' : ''
         }`}
         onClick={() => {
            if (!props.disabled) props.action();
         }}
      >
         <div className="body-standard-regular">{props.text}</div>
      </button>
   );
}
