import React from 'react';
import api from '../../lib/api';
import { IHelp } from '../../lib/types';
import ClearButton from '../clear-button/clear-button';
import ModalTextbox from '../modal-textbox/modal-textbox';
import PrimaryButton from '../primary-button/primary-button';
import ToastMessage from '../toast-message/toast-message';
import './help-modal.scss';

export interface IHelpModalProps {
   modalClass: string;
   setModalClass: React.Dispatch<React.SetStateAction<string>>;
}

export default function HelpModal(props: IHelpModalProps): JSX.Element {
   const successCheckSvg = new URL(
      '/public/images/success-check.svg',
      import.meta.url
   );
   const [helpFirstName, setHelpFirstName] = React.useState('');
   const [helpLastName, setHelpLastName] = React.useState('');
   const [helpEmail, setHelpEmail] = React.useState('');
   const [helpFirm, setHelpFirm] = React.useState('');
   const [helpMessage, setHelpMessage] = React.useState('');
   const [submitted, setSubmitted] = React.useState(false);
   const [toastClass, setToastClass] = React.useState('toast-hidden');
   const [toastbodyText, setToastBodyText] = React.useState('example');

   const submit = (): void => {
      if (
         helpFirstName.length == 0 ||
         helpLastName.length == 0 ||
         helpEmail.length == 0 ||
         helpFirm.length == 0 ||
         helpMessage.length == 0
      ) {
         setToastClass('');
         setToastBodyText('All fields are required');
      } else if (
         !helpEmail
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
      ) {
         setToastClass('');
         setToastBodyText('Invalid email address');
      } else {
         setSubmitted(true);
         const help: IHelp = {
            firstName: helpFirstName,
            lastName: helpLastName,
            email: helpEmail,
            firm: helpFirm,
            message: helpMessage,
         };
         api.postHelp(help);
      }
   };

   const hideModal = (): void => {
      setSubmitted(false);
      setHelpFirstName('');
      setHelpLastName('');
      setHelpEmail('');
      setHelpFirm('');
      setHelpMessage('');
      props.setModalClass('help-modal-hidden');
      setToastClass('toast-hidden');
   };

   return (
      <div className={`help-modal ${props.modalClass}`}>
         {!submitted ? (
            <div className="help-modal-wrapper">
               <div className="help-modal-nav-wrapper">
                  <div
                     className="help-modal-back body-standard-semibold"
                     onClick={() => props.setModalClass('modal-hidden')}
                  >
                     <div className="back-arrow"></div>
                     Back
                  </div>
                  <ClearButton action={hideModal} />
               </div>
               <div className="help-modal-header h1-headline-regular">
                  Help form
               </div>
               <div className="help-modal-body body-standard-regular">
                  You will receive a response from one of our team members within
                  one business day. If you need immediate assistance please
                  contact your CST representative. 
               </div>
               <ModalTextbox
                  labelText={'Your first name'}
                  textState={helpFirstName}
                  maxLength={150}
                  setTextState={setHelpFirstName}
               />
               <ModalTextbox
                  labelText={'Your last name'}
                  textState={helpLastName}
                  maxLength={150}
                  setTextState={setHelpLastName}
               />
               <ModalTextbox
                  labelText={'Your email'}
                  textState={helpEmail}
                  maxLength={150}
                  setTextState={setHelpEmail}
               />
               <ModalTextbox
                  labelText={'Your firm'}
                  textState={helpFirm}
                  maxLength={150}
                  setTextState={setHelpFirm}
               />
               <ModalTextbox
                  labelText={'Your message'}
                  textState={helpMessage}
                  maxLength={10000}
                  setTextState={setHelpMessage}
               />
               <PrimaryButton action={submit} text="Submit" />
               <ToastMessage
                  type="error"
                  headerText="Error"
                  bodyText={toastbodyText}
                  toastClass={toastClass}
                  setToastClass={setToastClass}
               />
            </div>
         ) : (
            <div className="help-modal-wrapper help-modal-success">
               <div className="help-modal-nav-wrapper">
                  <div className="help-modal-back body-standard-semibold"></div>
                  <ClearButton action={hideModal} />
               </div>
               <div className="help-modal-success-header h1-headline-regular">
                  <img src={successCheckSvg.toString()} />
                  Success!
               </div>
               <div className="help-modal-success-text body-standard-regular">
                  Your message has been sent. Please allow up to one business
                  day for response as we review the case and gather information.
               </div>
            </div>
         )}
      </div>
   );
}
