import { atom, selectorFamily } from 'recoil';
import api from '../lib/api';
import { ICustomerRecent } from '../lib/types';

export const customerRecentSelector = selectorFamily({
   key: 'customerRecentSelector',
   get: (affiliateId) => async (): Promise<ICustomerRecent[]> => {
      const customerRecents: ICustomerRecent[] | null =
         await api.getRecentCustomers(Number(affiliateId));
      if (!customerRecents) {
         return [];
      }
      return customerRecents;
   },
});

export const reloadRecentCustomers = atom<boolean>({
   key: 'reloadRecentCustomers',
   default: false,
});
