import { PublicClientApplication } from '@azure/msal-browser';
import appConfig from './app-config';

// Possible OAuth scopes for the app
export enum AuthScope {
   affiliate = 1,
   admin,
}

// Azure AD client
// TODO: Remove this and create client on demand using createADClientForLogin
export const adClient = new PublicClientApplication({
   auth: {
      clientId: appConfig.auth.clientId,
      authority: appConfig.auth.authorities.signUpSignIn.authority,
      knownAuthorities: [appConfig.auth.authorityDomain],
      redirectUri: appConfig.auth.redirectUri,
   },
});

export function createADClientForSignupSignin() {
   return createADClient(appConfig.auth.authorities.signUpSignIn.authority);
}

export function getJson<T>(url: string, scope?: AuthScope): Promise<T | null> {
   return httpJson(url, 'GET', undefined, scope);
}

export function putJson<T>(
   url: string,
   body?: unknown,
   scope?: AuthScope
): Promise<T | null> {
   return httpJson(url, 'PUT', body, scope);
}

export function postJson<T>(
   url: string,
   body?: unknown,
   scope?: AuthScope
): Promise<T | null> {
   return httpJson(url, 'POST', body, scope);
}

export function deleteJson<T>(
   url: string,
   body?: unknown,
   scope?: AuthScope
): Promise<T | null> {
   return httpJson(url, 'POST', body, scope);
}

export async function getBytes(url: string, scope?: AuthScope): Promise<Blob> {
   const init: RequestInit = { method: 'GET' };
   let error: unknown | undefined;
   try {
      init.headers = await getAuthHeader(scope);
      const resp = await fetch(new Request(url, init));
      if (resp.ok) {
         return await resp.blob();
      } else {
         const msg = `Call to  ${url} did not succeed. Status:  ${resp.status} ${resp.statusText}`;
         error = new Error(msg);
      }
   } catch (e) {
      console.log(`Unexpected error calling ${url}: ${e}`);
      error = e;
   }

   throw error;
}

async function httpJson<T>(
   url: string,
   method: string,
   body?: unknown,
   scope?: AuthScope
): Promise<T | null> {
   let result: T | null = null;
   let error: unknown | undefined;
   try {
      const init: RequestInit = { method };
      let headers = await getAuthHeader(scope);
      if ((method == 'POST' || method == 'PUT') && body) {
         init.body = JSON.stringify(body);
         headers = {
            ...headers,
            'Content-Type': 'application/json; charset=UTF-8',
         };
      }
      init.headers = headers;

      const resp = await fetch(new Request(url, init));

      if (resp.ok) {
         result = resp.json() as T;
      } else {
         const msg = `Call to  ${url} did not succeed. Status:  ${resp.status} ${resp.statusText}`;
         error = new Error(msg);
      }
   } catch (e) {
      console.log(`Unexpected error calling ${url}: ${e}`);
      error = e;
   }

   if (error) {
      throw error;
   }

   return result;
}

async function getAuthToken(
   scopes: Array<string>,
   adInstance: PublicClientApplication
): Promise<string> {
   let account = adInstance.getActiveAccount();
   if (account == null) {
      const accounts = adInstance.getAllAccounts();
      if (accounts && accounts.length > 0) {
         account = accounts[0];
      }
   }

   if (!account) {
      throw new Error(
         'Unable to obtain auth token because an account could not be found.'
      );
   }

   let token = '';
   const tokenRequest = {
      scopes,
      account: account,
   };
   try {
      const result = await adInstance.acquireTokenSilent(tokenRequest);
      if (result != null) {
         token = result.accessToken;
      }
   } catch (e) {
      try {
         const result = await adInstance.acquireTokenPopup(tokenRequest);
         if (result != null) {
            token = result.accessToken;
         }
      } catch (e2) {
         console.log(`Failed to retrieve token ${e}`);
      }
   }

   return token;
}

async function getAuthHeader(scope?: AuthScope): Promise<HeadersInit> {
   if (scope) {
      const scopeVal =
         scope === AuthScope.affiliate
            ? appConfig.auth.scopes.user
            : appConfig.auth.scopes.admin;
      const scopes = [scopeVal];
      const token = await getAuthToken(scopes, adClient);
      return {
         Authorization: `Bearer ${token}`,
      };
   }
   return {};
}

function createADClient(authority: string): PublicClientApplication {
   return new PublicClientApplication({
      auth: {
         clientId: appConfig.auth.clientId,
         authority,
         knownAuthorities: [appConfig.auth.authorityDomain],
         redirectUri: appConfig.auth.redirectUri,
      },
   });
}
