import React from 'react';
import './toast-message.scss';

export interface IToastMessageProps {
   type: string;
   headerText: string;
   bodyText: string;
   toastClass: string;
   setToastClass: React.Dispatch<React.SetStateAction<string>>;
}

export default function ToastMessage(props: IToastMessageProps): JSX.Element {
   const badgeViolationSvg = new URL(
      '/public/images/badge-violation.svg',
      import.meta.url
   );
   return (
      <div className={`toast-message ${props.toastClass}`}>
         <div className="toast-message-nav-wrapper">
            <img src={badgeViolationSvg.toString()} />
            <div className="toast-message-header label-xsmall">
               {props.headerText}
            </div>
            <div
               className="toast-message-back body-standard-semibold back-arrow"
               onClick={() => props.setToastClass('toast-hidden')}
            ></div>
         </div>
         <div className="toast-message-body body-small-regular">
            {props.bodyText}
         </div>
      </div>
   );
}
