import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAffiliateShort } from '../../lib/types';
import './card-affiliate.scss';

export interface ICardAffiliateProps {
   affiliate: IAffiliateShort;
}

export default function CardAffiliate(props: ICardAffiliateProps): JSX.Element {
   const unverifiedBadgeSvg = new URL(
      '/public/images/unverified-badge.svg',
      import.meta.url
   );
   const ellipse3Svg = new URL('/public/images/ellipse-3.svg', import.meta.url);
   const navigate = useNavigate();

   function getAffiliateDetails(): void {
      navigate(`/affiliate-details/${props.affiliate.id}`);
   }

   return (
      <div
         className={`${assignHoverClass(props.affiliate)} card-affiliate `}
         onClick={() => getAffiliateDetails()}
      >
         <div className="card-affiliate-name">
            <div className="affiliate-header-wrapper">
               {!props.affiliate.vetted && (
                  <div className="affiliate-unverified-label label-small-allcaps">
                     <img src={unverifiedBadgeSvg.toString()} />
                     unverified
                  </div>
               )}
               {props.affiliate.deactivated && (
                  <div className="affiliate-unverified-label label-small-allcaps">
                     <img src={unverifiedBadgeSvg.toString()} />
                     deactivated
                  </div>
               )}
               <div className="affiliate-type body-standard-regular">
                  {props.affiliate.title}
               </div>
            </div>
            <div className="affiliate-name h1-headline-regular">
               {props.affiliate.firstName} {props.affiliate.lastName}
            </div>
         </div>
         <div className="card-affiliate-firm">
            <div className="card-affiliate-firm-name body-standard-regular">
               {props.affiliate.organizationName}
            </div>
            <div className="card-affiliate-location-wrapper">
               <div className="card-affiliate-firm-city body-small-regular">
                  {props.affiliate.organizationCity}
               </div>
               <img src={ellipse3Svg.toString()} />
               <div className="card-affiliate-firm-state body-small-regular">
                  {props.affiliate.organizationState}
               </div>
            </div>
         </div>
      </div>
   );
}

function assignHoverClass(affiliate: IAffiliateShort): string {
   if (affiliate.vetted) return 'affiliate-verified';
   else return 'affiliate-unverified';
}
