import React from 'react';
import { IAffiliateInvite, ICustomerInvite, IInvite } from '../../lib/types';
import InviteModal from '../invite-modal/invite-modal';
import './invite-button.scss';

export interface IInviteButtonProps {
   inviteTarget: string;
   headerText: string;
   bodyText: string;
   confirmText: string;
   successTextPre: string;
   successTextPost: string;
   inviteFunction: (
      invite: IInvite
   ) => Promise<IAffiliateInvite | null> | Promise<ICustomerInvite | null>;
}

export default function InviteButton(props: IInviteButtonProps): JSX.Element {
   const plusSvg = new URL('/public/images/plus.svg', import.meta.url);
   const [modalClass, setModalClass] = React.useState('modal-hidden');

   const showModal = (): void => {
      setModalClass('');
   };

   return (
      <div className="invite-button-wrapper">
         <button className="invite-button" onClick={() => showModal()}>
            <div className="invite-icon">
               <img src={plusSvg.toString()} />
            </div>

            <div className="body-standard-regular">
               Invite a New {props.inviteTarget}
            </div>
         </button>
         <InviteModal
            inviteTarget={props.inviteTarget}
            headerText={props.headerText}
            bodyText={props.bodyText}
            confirmText={props.confirmText}
            successTextPre={props.successTextPre}
            successTextPost={props.successTextPost}
            modalClass={modalClass}
            setModalClass={setModalClass}
            submitFunction={props.inviteFunction}
         />
      </div>
   );
}
