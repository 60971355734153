import React, { useEffect, useRef } from 'react';
import {
   RecoilState,
   RecoilValueReadOnly,
   useRecoilState,
   useRecoilValue,
} from 'recoil';
import FilterSearchItem from '../filter-search-item/filter-search-item';
import './filter-search.scss';

export interface IFilterSearchProps {
   searchQueryAtom: RecoilState<string>;
   resultsSelector: RecoilValueReadOnly<string[]>;
   currentFilters: RecoilState<string[]>;
   clearHeader: boolean;
   uniqueName: string;
}

export default function FilterSearch(props: IFilterSearchProps): JSX.Element {
   const [dropdownClass, setDropdownClass] = React.useState('hidden');
   const [searchQuery, setSearchQuery] = useRecoilState(props.searchQueryAtom);

   const inputRef = useRef<HTMLInputElement>(null);

   const focusTextbox = (): void => {
      inputRef.current && inputRef.current.focus();
   };

   const handleChange = (e): void => {
      setSearchQuery((e.target as HTMLInputElement).value);
   };

   const onFocus = (): void => {
      setDropdownClass('');
   };

   const results: string[] = useRecoilValue(props.resultsSelector);

   const wrapperRef = useRef(null);
   detectClick(wrapperRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className !=
                  `filter-dropdown  ${props.uniqueName}` &&
               event.target.className !=
                  `filter-dropdown-text body-standard-regular ${props.uniqueName}` &&
               event.target.className !=
                  `filter-dropdown-icon-container ${props.uniqueName}` &&
               event.target.className !=
                  `filter-dropdown-icon-border ${props.uniqueName}` &&
               event.target.className != 'filter-dropdown-icon' &&
               event.target.className != 'filter-clear-header' &&
               event.target.className !=
                  `filter-dropdown filter-dropdown-active ${props.uniqueName}` &&
               event.target.className !=
                  'filter-clear-header-text body-small-semibold'
            ) {
               setDropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <div className={`filter-search  ${props.uniqueName}`}>
         <div className="filter-search-wrapper" onClick={focusTextbox}>
            <div className="search-icon"></div>
            <input
               className="filter-input body-standard-regular"
               type="text"
               placeholder={
                  props.uniqueName.charAt(0).toUpperCase() +
                  props.uniqueName.slice(1)
               }
               value={searchQuery}
               onFocus={onFocus}
               onChange={(e) => handleChange(e)}
               ref={inputRef}
            />
         </div>
         <div
            ref={wrapperRef}
            className={`filter-dropdown-dropdown ${dropdownClass} ${
               results.length == 0 ? 'hidden' : ''
            }`}
         >
            {results.map((filter, index) => (
               <FilterSearchItem
                  text={filter}
                  searchQueryAtom={props.searchQueryAtom}
                  filterAtom={props.currentFilters}
                  key={index}
                  setDropdownClass={setDropdownClass}
               />
            ))}
         </div>
      </div>
   );
}
