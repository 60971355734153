import React from 'react';
import { IAffiliate, IAffiliateDeactivated } from '../../lib/types';
import './deactivate-affiliate-button.scss';

export interface IDeactivateAffiliateButtonProps {
   deactivate: (IAffiliate, IAffiliateDeactivated) => void;
   affiliate: IAffiliate;
   affiliateDeactivated: IAffiliateDeactivated;
   disabled: boolean;
   text: string;
}

export default function DeactivateAffiliateButton(
   props: IDeactivateAffiliateButtonProps
): JSX.Element {
   return (
      <button
         className={`deactivate-affiliate-button ${
            props.disabled ? 'deactivate-affiliate-disabled' : ''
         }`}
         onClick={() => {
            if (!props.disabled)
               props.deactivate(props.affiliate, props.affiliateDeactivated);
         }}
      >
         <div className="body-standard-regular">{props.text}</div>
      </button>
   );
}
