import React from 'react';
import {
   getClaimEmail,
   getClaimFirstName,
   getClaimLastName,
} from '../../lib/authorization';
import './claims-profile-content.scss';

export default function ClaimsProfileContent(): JSX.Element {
   const emailDisabledeSvg = new URL(
      '/public/images/email-disabled.svg',
      import.meta.url
   );

   return (
      <div className="claims-profile-content">
         <div className="affiliate-name h1-headline-regular">
            {getClaimFirstName() + ' ' + getClaimLastName()}
         </div>
         <div className="personal-label label-xsmall-allcaps">personal</div>
         <div className="email-wrapper">
            <img src={emailDisabledeSvg.toString()} />
            <div className="profile-email body-standard-regular">
               {getClaimEmail()}
            </div>
         </div>
         <div className="profile-help-text body-small-regular">
            Please call intoxalock at{' '}
            <span className="profile-help-number">617-283-1291</span> to manage
            or update your profile information.
         </div>
      </div>
   );
}
