import React, { useEffect, useRef } from 'react';
import { RecoilState, useRecoilState, useSetRecoilState } from 'recoil';
import { ISortType, Sort, SortDirection } from '../../lib/types';
import { sortDirectionAtom } from '../../recoil/customers-sort-filter';
import SortDropdown from '../sort-dropdown/sort-dropdown';
import './sort-button.scss';

export interface ISortButtonProps {
   disabled: boolean;
   sortAtom: RecoilState<Sort>;
   sorts: ISortType[];
}

export default function SortButton(props: ISortButtonProps): JSX.Element {
   const sortSvg = new URL('/public/images/sort.svg', import.meta.url);
   const [checked, setChecked] = React.useState(false);
   const [dropdownClass, setDropdownClass] = React.useState('hidden');
   const [sort, setSort] = useRecoilState(props.sortAtom);
   const setSortDirection = useSetRecoilState(sortDirectionAtom);

   const handleChange = (): void => {
      setChecked(!checked);
      checked ? setDropdownClass('hidden') : setDropdownClass('');
   };

   const clearSort = (): void => {
      setChecked(false);
      setDropdownClass('hidden');
      setSort(Sort.default);
      setSortDirection(SortDirection.ascending);
   };

   const wrapperRef = useRef(null);
   detectClick(wrapperRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'sort-button-image' &&
               event.target.className != 'sort-button' &&
               event.target.className != 'sort-button sort-button-checked '
            ) {
               setChecked(false);
               setDropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <label
         className={`sort-button ${
            (checked || sort != Sort.default) && !props.disabled
               ? 'sort-button-checked'
               : 'sort-button-not-checked'
         } ${props.disabled ? 'sort-button-disabled' : ''}`}
      >
         <img src={sortSvg.toString()} className="sort-button-image" />
         <input
            className="checkbox"
            type="checkbox"
            onChange={handleChange}
            disabled={props.disabled}
            checked={checked || sort != Sort.default}
         />
         <SortDropdown
            dropdownRef={wrapperRef}
            className={dropdownClass}
            sorts={props.sorts}
            sortAtom={props.sortAtom}
            clearSort={clearSort}
         />
      </label>
   );
}
