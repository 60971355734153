import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import BackButton from '../components/back-button/back-button';
import CustomerDetailsHeader from '../components/customer-details-header/customer-details-header';
import { CustomerDocuments } from '../components/customer-documents/customer-documents';
import DatesAndDocuments from '../components/dates-and-documents/dates-and-documents';
import ImpersonateHeader from '../components/impersonate-header/impersonate-header';
import api from '../lib/api';
import { IAffiliate, ICustomer, ICustomerRecent } from '../lib/types';
import { affiliateAtom } from '../recoil/affiliate';
import { reloadRecentCustomers } from '../recoil/customer-recent';
import { customersAtom } from '../recoil/customers';
import './customer-details.scss';

export default function CustomerDetailsPage(): JSX.Element {
   const customers: ICustomer[] = useRecoilValue(customersAtom);

   function getCustomerObject(): ICustomer {
      const { id } = useParams();
      const customer: ICustomer | undefined = customers.find(
         (c) => c.id === Number(id)
      );
      if (customer == null) {
         throw new Error(`No customer found with id: '${id}'`);
      }
      return customer;
   }

   const customer: ICustomer = getCustomerObject();
   const affiliate: IAffiliate | null = useRecoilValue(affiliateAtom);
   const setReloadRecent = useSetRecoilState(reloadRecentCustomers);

   if (affiliate) {
      const CustomerRecent: ICustomerRecent = {
         customerId: customer.id,
         affiliateId: affiliate.id,
      };
      useEffect(() => {
         setReloadRecent(true);
         api.postRecentCustomer(CustomerRecent);
      }, []);
   }

   return (
      <div className="customer-details">
         <div className="app-body">
            <ImpersonateHeader />
            <AppHeader overrideName={true} />
            <div className="customer-details-body">
               <div className="customer-details-left">
                  <BackButton />
                  <div className="customer-details-left-body-wrapper-row">
                     <div className="customer-details-left-body-wrapper-column">
                        <CustomerDetailsHeader customer={customer} />
                        <CustomerDocuments
                           customer={customer}
                        ></CustomerDocuments>
                     </div>
                  </div>
               </div>
               <DatesAndDocuments customer={customer} />
            </div>
         </div>
         <AppFooter extraHeight={true} />
      </div>
   );
}
