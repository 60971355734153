var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { regionAPIs } from "./constants";
import { init, getIntercomRef, ref } from "./instance-manager";
export const Intercom = (_a) => {
    var { region = 'us' } = _a, args = __rest(_a, ["region"]);
    if (typeof window !== "undefined" && !ref) {
        window.intercomSettings = Object.assign(Object.assign({}, args), { api_base: regionAPIs.get(region) });
        init();
    }
};
// Public functions that can be called from outside the module
export default Intercom;
export const boot = (arg) => {
    getIntercomRef()("boot", arg);
};
export const shutdown = () => {
    getIntercomRef()("shutdown");
};
export const update = (arg) => {
    getIntercomRef()("update", arg);
};
export const hide = () => {
    getIntercomRef()("hide");
};
export const show = () => {
    getIntercomRef()("show");
};
export const showSpace = (spaceName) => {
    getIntercomRef()("showSpace", spaceName);
};
export const showMessages = () => {
    getIntercomRef()("showMessages");
};
export const showNewMessage = (prePopulatedContent) => {
    getIntercomRef()("showNewMessage", prePopulatedContent);
};
export const onHide = (callback) => {
    getIntercomRef()("onHide", callback);
};
export const onShow = (callback) => {
    getIntercomRef()("onShow", callback);
};
export const onUnreadCountChange = (callback) => {
    getIntercomRef()("onUnreadCountChange", callback);
};
export const trackEvent = (...args) => {
    getIntercomRef()("trackEvent", ...args);
};
export const getVisitorId = () => {
    getIntercomRef()("getVisitorId");
};
export const startTour = (tourId) => {
    getIntercomRef()("startTour", tourId);
};
export const showArticle = (articleId) => {
    getIntercomRef()("showArticle", articleId);
};
export const showNews = (newsItemId) => {
    getIntercomRef()("showNews", newsItemId);
};
export const startSurvey = (surveyId) => {
    getIntercomRef()("startSurvey", surveyId);
};
export const startChecklist = (checklistId) => {
    getIntercomRef()("startChecklist", checklistId);
};
export const showTicket = (ticketId) => {
    getIntercomRef()("showTicket", ticketId);
};
export const showConversation = (conversationId) => {
    getIntercomRef()("showConversation", conversationId);
};
export const onUserEmailSupplied = (callback) => {
    getIntercomRef()("onUserEmailSupplied", callback);
};
