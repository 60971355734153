import React, { useEffect, useRef } from 'react';
import CopyButton from '../copy-button/copy-button';
import './location-button.scss';

export interface ILocationButtonProps {
   disabled: boolean;
   location1: string;
   location2: string;
}

export default function LocationButton(
   props: ILocationButtonProps
): JSX.Element {
   const [checked, setChecked] = React.useState(false);
   const [dropdownClass, setdropdownClass] = React.useState('hidden');

   const handleChange = (): void => {
      setChecked(!checked);
      checked ? setdropdownClass('hidden') : setdropdownClass('');
   };

   const contactRef = useRef(null);
   detectClick(contactRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'location-button-icon' &&
               event.target.className != 'location-button' &&
               event.target.className != 'location-button location-checked ' &&
               event.target.className !=
                  'location-button-dropdown-number body-standard-regular' &&
               event.target.className != 'location-button-dropdown'
            ) {
               setChecked(false);
               setdropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <label
         className={`location-button ${checked ? 'location-checked' : ''} ${
            props.disabled ? 'location-disabled' : ''
         }`}
      >
         <input
            className="location-checkbox"
            type="checkbox"
            onChange={handleChange}
            disabled={props.disabled}
            checked={checked}
         />
         <div className="location-button-icon"></div>
         <div
            ref={contactRef}
            onClick={(e) => e.preventDefault()}
            className={`location-button-dropdown ${dropdownClass}`}
         >
            <div className="location-button-dropdown-address-wrapper">
               <div className="location-button-dropdown-address body-standard-regular">
                  {props.location1}
               </div>
               <div className="location-button-dropdown-address body-standard-regular">
                  {props.location2}
               </div>
            </div>
            <CopyButton copyText={props.location1 + ' ' + props.location2} />
         </div>
      </label>
   );
}
