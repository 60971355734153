import React from 'react';
import { useRecoilValue } from 'recoil';
import AdminProfileContent from '../components/admin-profile-content/admin-profile-content';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import BackButton from '../components/back-button/back-button';
import ClaimsProfileContent from '../components/claims-profile-content/claims-profile-content';
import ImpersonateHeader from '../components/impersonate-header/impersonate-header';
import Modal, { IModalProps } from '../components/modal/modal';
import ProfileContent from '../components/profile-content/profile-content';
import SecondaryButton from '../components/secondary-button/secondary-button';
import { isAdmin, isAffiliate, logout } from '../lib/authorization';
import { IAffiliateImpersonation } from '../lib/types';
import { adminImpersonateCookie } from '../recoil/admin-impersonate';
import './profile.scss';

export default function Profile(): JSX.Element {
   const [modalClass, setModalClass] = React.useState('modal-hidden');

   const modalConfirm = (): void => {
      logout('redirect');
   };
   const modalProps: IModalProps = {
      headerText: 'You are about to sign out of your Intoxalock account.',
      bodyText: 'Would you like to proceed?',
      confirmText: 'Sign Out',
      modalClass: modalClass,
      setModalClass: setModalClass,
      confirmAction: modalConfirm,
   };

   const showModal = (): void => {
      setModalClass('');
   };

   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );

   return (
      <div className="profile">
         <div className="app-body">
            <ImpersonateHeader />
            <AppHeader overrideName={true} />
            <div className="profile-body">
               <BackButton />
               {isAdmin() && !cookie ? (
                  <AdminProfileContent />
               ) : isAffiliate() ? (
                  <ProfileContent />
               ) : (
                  <ClaimsProfileContent />
               )}
               <SecondaryButton
                  text="Sign Out"
                  action={showModal}
                  className="sign-out-button"
               />
               <Modal
                  headerText={modalProps.headerText}
                  bodyText={modalProps.bodyText}
                  confirmText={modalProps.confirmText}
                  modalClass={modalProps.modalClass}
                  setModalClass={modalProps.setModalClass}
                  confirmAction={modalProps.confirmAction}
               />
            </div>
         </div>
         <AppFooter />
      </div>
   );
}
