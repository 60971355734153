import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { ISearchResult } from '../../lib/types';
import './search-dropdown-item.scss';

export interface ISearchDropdownItemProps {
   index: number;
   searchResultSelector: RecoilValueReadOnly<ISearchResult[]>;
   searchById: boolean;
   resultPageLink: string;
}

export default function SearchDropdownItem(
   props: ISearchDropdownItemProps
): JSX.Element {
   const searchHoverSvg = new URL(
      '/public/images/search-hover.svg',
      import.meta.url
   );
   const result: ISearchResult = useRecoilValue(props.searchResultSelector)[
      props.index
   ];
   const navigate = useNavigate();

   function highlightResult(field: string): JSX.Element[] {
      // no highlight needed, result is detected in different field
      if (result && field != result.key) {
         return result.entity[field];
      }

      if (result && result.key) {
         const elements: JSX.Element[] = [];
         let pointer = 0; // keep track of how much of the target has been processed
         let key = 0; // key for appended JSX elements
         const target: string =
            field == 'id'
               ? result.entity[field].toString()
               : result.entity[field]; // string to be highlighted

         for (const index in result.indices) {
            const hStart: number = result.indices[index][0]; // start of current highlight
            const hEnd: number = result.indices[index][1]; // end of current highlight

            // do not highlight before start
            if (hStart != pointer) {
               elements.push(
                  <span key={key}>{target.substring(pointer, hStart)}</span>
               );
               pointer = hStart;
               key += 1;
            }

            // highlight after start
            elements.push(
               <span key={key} className="highlight">
                  {target.substring(hStart, hEnd + 1)}
               </span>
            );
            key += 1;
            pointer = hEnd + 1;
         }

         // do not highlight if there is a leftover after the last highlight
         if (pointer < target.length) {
            elements.push(<span key={key}>{target.substring(pointer)}</span>);
         }
         return elements;
      }
      return [];
   }

   function getDetails(): void {
      navigate(`/${props.resultPageLink}/${result.entity.id}`);
   }

   return (
      <div
         className={`search-dropdown-item ${result ? '' : 'invisible'}`}
         onClick={() => getDetails()}
      >
         <img src={searchHoverSvg.toString()} />
         <div className="search-name h1-headline-light">
            {highlightResult('name')}
         </div>
         {props.searchById && <div className="search-id-label h2-mono">ID</div>}
         {props.searchById && (
            <div className="search-id h2-mono">{highlightResult('id')}</div>
         )}
      </div>
   );
}
