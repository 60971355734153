import { SerializableParam, atomFamily, selectorFamily } from 'recoil';
import api from '../lib/api';
import { ICustomerDocument } from '../lib/types';
import { affiliateAtom } from './affiliate';

export const customerDocumentsAtomFamily = atomFamily<
   ICustomerDocument[],
   SerializableParam
>({
   key: 'customerDocuments',
   default: selectorFamily({
      key: 'customerDocumentsSelector',
      get:
         (customerId) =>
         async ({ get }) => {
            const affiliate = get(affiliateAtom);
            if (affiliate == null) {
               return [];
            }
            const customerDocuments = await api.getCustomerDocuments(
               affiliate.id,
               Number(customerId)
            );
            if (customerDocuments == null) {
               return [];
            }
            return customerDocuments
               .slice()
               .sort((a, b) => (a.createdUtc > b.createdUtc ? -1 : 1));
         },
   }),
});
