import React from 'react';
import { useRecoilValue } from 'recoil';
import { Sort } from '../../lib/types';
import {
   adminCityFiltersAtom,
   adminCitySearchQueryAtom,
   searchedCitySelector,
} from '../../recoil/admin-city-search';
import {
   adminFirmFiltersAtom,
   adminFirmSearchQueryAtom,
   searchedFirmSelector,
} from '../../recoil/admin-firm-search';
import { adminSortAtom } from '../../recoil/admin-sort-filter';
import {
   adminStateFiltersAtom,
   adminStateSearchQueryAtom,
   searchedStateSelector,
} from '../../recoil/admin-state-search';
import FilterPill from '../filter-pill/filter-pill';
import FilterSearch from '../filter-search/filter-search';
import SortButton, { ISortButtonProps } from '../sort-button/sort-button';
import './admin-sort-filter.scss';

export default function AdminSortFilter(): JSX.Element {
   const sortButtonProps: ISortButtonProps = {
      disabled: false,
      sortAtom: adminSortAtom,
      sorts: [
         { sort: Sort.firstName, ascendingDescending: true },
         { sort: Sort.lastName, ascendingDescending: true },
      ],
   };
   const firmFilters: string[] = useRecoilValue(adminFirmFiltersAtom);
   const cityFilters: string[] = useRecoilValue(adminCityFiltersAtom);
   const stateFilters: string[] = useRecoilValue(adminStateFiltersAtom);

   return (
      <div className="admin-sort-filter">
         <div className="admin-filters-sort">
            <FilterSearch
               searchQueryAtom={adminFirmSearchQueryAtom}
               resultsSelector={searchedFirmSelector}
               currentFilters={adminFirmFiltersAtom}
               clearHeader={true}
               uniqueName="firm"
            />
            <FilterSearch
               searchQueryAtom={adminCitySearchQueryAtom}
               resultsSelector={searchedCitySelector}
               currentFilters={adminCityFiltersAtom}
               clearHeader={true}
               uniqueName="city"
            />
            <FilterSearch
               searchQueryAtom={adminStateSearchQueryAtom}
               resultsSelector={searchedStateSelector}
               currentFilters={adminStateFiltersAtom}
               clearHeader={true}
               uniqueName="state"
            />
            <SortButton
               disabled={sortButtonProps.disabled}
               sortAtom={sortButtonProps.sortAtom}
               sorts={sortButtonProps.sorts}
            />
         </div>
         <div className="admin-filter-pills">
            {(firmFilters.length > 0 ||
               cityFilters.length > 0 ||
               stateFilters.length > 0) && (
               <div className="body-standard-semibold">Filters:</div>
            )}
            {firmFilters.map((f) => (
               <FilterPill
                  text={f}
                  currentFilters={adminFirmFiltersAtom}
                  key={f}
               />
            ))}
            {cityFilters.map((f) => (
               <FilterPill
                  text={f}
                  currentFilters={adminCityFiltersAtom}
                  key={f}
               />
            ))}
            {stateFilters.map((f) => (
               <FilterPill
                  text={f}
                  currentFilters={adminStateFiltersAtom}
                  key={f}
               />
            ))}
         </div>
      </div>
   );
}
