import React from 'react';
import { useRecoilValue } from 'recoil';
import {
   getClaimEmail,
   getClaimFirstName,
   getClaimLastName,
} from '../../lib/authorization';
import { IAffiliate, IAffiliateImpersonation } from '../../lib/types';
import { adminImpersonateCookie } from '../../recoil/admin-impersonate';
import { affiliateAtom } from '../../recoil/affiliate';
import ClaimsProfileContent from '../claims-profile-content/claims-profile-content';
import './profile-content.scss';

export default function ProfileContent(): JSX.Element {
   const emailDisabledeSvg = new URL(
      '/public/images/email-disabled.svg',
      import.meta.url
   );
   const contactDisabledSvg = new URL(
      '/public/images/contact-disabled.svg',
      import.meta.url
   );
   const locationDisabledSvg = new URL(
      '/public/images/location-disabled.svg',
      import.meta.url
   );
   let affiliate: IAffiliate | null = null;
   affiliate = useRecoilValue(affiliateAtom);
   if (!affiliate) {
      return <ClaimsProfileContent />;
   }

   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );

   return (
      <div className="profile-content">
         <div className="affiliate-label body-standard-regular">
            {affiliate.title}
         </div>
         <div className="affiliate-name h1-headline-regular">
            {cookie
               ? affiliate.firstName + ' ' + affiliate.lastName
               : getClaimFirstName() + ' ' + getClaimLastName()}
         </div>
         <div className="personal-label label-xsmall-allcaps">personal</div>
         <div className="email-wrapper">
            <img src={emailDisabledeSvg.toString()} />
            <div className="profile-email body-standard-regular">
               {cookie ? affiliate.email : getClaimEmail()}
            </div>
         </div>
         <div className="contact-wrapper">
            <img src={contactDisabledSvg.toString()} />
            <div className="profile-contact body-standard-regular">
               {affiliate.phone}
            </div>
         </div>
         <div className="address-wrapper">
            <img src={locationDisabledSvg.toString()} />
            <div className="address-text-wrapper">
               <div className="profile-address-1 body-standard-regular">
                  {affiliate.address +
                     (affiliate.address2 != null
                        ? ' ' + affiliate.address2
                        : '')}
               </div>
               <div className="profile-address-2 body-standard-regular">
                  {affiliate.city +
                     ', ' +
                     affiliate.state +
                     ' ' +
                     affiliate.zip}
               </div>
            </div>
         </div>
         <div className="firm-label label-xsmall-allcaps">firm</div>
         <div className="firm-wrapper">
            <div className="profile-firm-label body-standard-regular">Firm</div>
            <div className="profile-firm body-standard-regular">
               {affiliate.organizationName}
            </div>
         </div>
         <div className="city-wrapper">
            <div className="profile-city-label body-standard-regular">City</div>
            <div className="profile-city body-standard-regular">
               {affiliate.organizationCity}
            </div>
         </div>
         <div className="state-wrapper">
            <div className="profile-state-label body-standard-regular">
               State
            </div>
            <div className="profile-state body-standard-regular">
               {affiliate.organizationState}
            </div>
         </div>
         <div className="profile-help-text body-small-regular">
            Please call intoxalock at{' '}
            <span className="profile-help-number">617-283-1291</span> to manage
            or update your profile information.
         </div>
      </div>
   );
}
