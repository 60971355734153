import React from 'react';
import { useRecoilValue } from 'recoil';
import { getClaimFirstName } from '../../lib/authorization';
import { IAffiliate } from '../../lib/types';
import { affiliateAtom } from '../../recoil/affiliate';
import {
   customersSearchQueryAtom,
   searchedCustomersSelector,
} from '../../recoil/customer-search';
import HelpModal from '../help-modal/help-modal';
import PrimaryButton from '../primary-button/primary-button';
import Search, { ISearchProps } from '../search/search';
import SortFilter from '../sort-filter/sort-filter';
import './affiliate-unverified.scss';

export default function AffiliateUnverified(): JSX.Element {
   const inProgressSvg = new URL(
      '/public/images/in-progress.svg',
      import.meta.url
   );
   const affiliate: IAffiliate | null = useRecoilValue(affiliateAtom);
   const [modalClass, setModalClass] = React.useState('modal-hidden');

   const searchProps: ISearchProps = {
      placeholderPrimary: 'for an Active Client ',
      placeholderSecondary: 'by name or customer ID',
      searchQueryAtom: customersSearchQueryAtom,
      searchResultSelector: searchedCustomersSelector,
      resultPageLink: 'client-details',
      searchById: true,
      disabled: true,
   };

   const showModal = (): void => {
      setModalClass('');
   };

   return (
      <div className="unverified-view">
         <Search
            placeholderPrimary={searchProps.placeholderPrimary}
            placeholderSecondary={searchProps.placeholderSecondary}
            searchQueryAtom={searchProps.searchQueryAtom}
            searchResultSelector={searchProps.searchResultSelector}
            searchById={searchProps.searchById}
            resultPageLink={searchProps.resultPageLink}
            disabled={searchProps.disabled}
         />
         <SortFilter disabled={true} />

         <div className="content-wrapper">
            <div className="unverified-content">
               <div className="in-progress">
                  <img src={inProgressSvg.toString()} />
               </div>
               {affiliate && affiliate.deactivated ? (
                  <div className="large-type primary-dark-2">
                     Hi {affiliate.firstName}, your account is deactivated.
                  </div>
               ) : (
                  <div className="large-type primary-dark-2">
                     Hi {getClaimFirstName()}, we’re in the process of verifying
                     your account.
                  </div>
               )}
               {(!affiliate || !affiliate.deactivated) && (
                  <div className="body-large-light primary-dark-3">
                     Our team is reviewing your profile and connecting
                     information with our shared clients. We’ll be back in just
                     a minute.
                  </div>
               )}
               <PrimaryButton
                  className="button-link-create-account"
                  text="Get Help"
                  action={showModal}
               />
               <HelpModal
                  modalClass={modalClass}
                  setModalClass={setModalClass}
               />
            </div>
         </div>
      </div>
   );
}
