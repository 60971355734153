import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import appConfig from '../../lib/app-config';

/**
 * Component: Adds intercom chatbot to the parent component/page
 * @returns Intercom chatbot
 */
function IntercomChat() {
   Intercom({
      app_id: appConfig.intercom.appId,
      // user_id: UNIQUE_USER_ID
      // name: USER_NAME
   });
   return <></>;
}

export default IntercomChat;
