import React, { createRef, useState } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import api from '../../lib/api';
import { ICustomerDocument } from '../../lib/types';
import { affiliateAtom } from '../../recoil/affiliate';
import { documentUrlCacheAtom } from '../../recoil/document-url-cache';
import './download-button.scss';

export interface IDownloadButtonProps {
   doc?: ICustomerDocument;
   inverted: boolean;
   disabled?: boolean;
}

export default function DownloadButton(
   props: IDownloadButtonProps
): JSX.Element {
   const [isDownloading, setIsDownloading] = useState(false);
   const downloadLink = createRef<HTMLAnchorElement>();

   const downloadSvg = new URL('/public/images/download.svg', import.meta.url);

   if (props.disabled) {
      const invertedClass = props.inverted ? 'inverted' : '';
      const cssClass = `download-button ${invertedClass} download-button-disabled`;
      return (
         <div className={cssClass}>
            <img src={downloadSvg.toString()} />
         </div>
      );
   }

   const affiliate = useRecoilValue(affiliateAtom);
   const [, setDocumentUrlCache, getCurrentDocumentUrlCache] =
      useExtendedRecoilState(documentUrlCacheAtom);
   const affiliateId = affiliate?.id ?? 0;
   const docId = props.doc?.id ?? 0;
   const custId = props.doc?.customerId ?? 0;
   const downloadDoc = async () => {
      const anchor = downloadLink.current;
      let curDocUrlCache = await getCurrentDocumentUrlCache();
      if (anchor == null) return;
      if (anchor.href) return;
      if (isDownloading) return;
      try {
         setIsDownloading(true);
         const cacheEntry = curDocUrlCache.find((d) => d.documentId == docId);
         let href = '';
         if (cacheEntry) {
            href = cacheEntry.documentBlobUrl;
         } else {
            // Since retrieving a document requires a bearer token, we can't use an anchor tag to
            // download the document directly (because the browser will not add the auth header),
            // so we retrieve it ourselves, then create a download URL from the in-memory document
            // content.
            const blob = await api.downloadDocument(affiliateId, custId, docId);
            // This blob/URL will live as long as the window document does (until a page refresh)
            // We therefore keep track of which blobs are cached, and evict then once we reach a
            // threshold size of 5.
            if (curDocUrlCache.length == 5) {
               const [first, ...rest] = curDocUrlCache;
               window.URL.revokeObjectURL(first.documentBlobUrl);
               curDocUrlCache = rest;
               first.onEvict();
               setDocumentUrlCache(rest);
            }
            href = window.URL.createObjectURL(blob);
            setDocumentUrlCache([
               ...curDocUrlCache,
               {
                  documentId: docId,
                  documentBlobUrl: href,
                  onEvict: () => {
                     anchor.removeAttribute('href');
                  },
               },
            ]);
         }
         anchor.download = 'client_doc';
         anchor.href = href;
         anchor.click();
      } finally {
         setIsDownloading(false);
      }
   };

   return (
      <a
         className={`download-button ${props.inverted ? 'inverted' : ''}`}
         target="_blank"
         rel="noopener noreferrer"
         download
         onClick={() => downloadDoc()}
         ref={downloadLink}
      >
         <img src={downloadSvg.toString()} />
      </a>
   );
}

// Similar to useRecoilState, but also provides a function to retrieve the current state (useful
// within DOM callbacks to avoid accessing stale data.)
// https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
function useExtendedRecoilState<T>(recoilState: RecoilState<T>) {
   const [state, setState] = useRecoilState(recoilState);
   const getLatestState = () => {
      return new Promise<T>((resolve, reject) => {
         setState((s) => {
            resolve(s);
            return s;
         });
      });
   };

   return [state, setState, getLatestState] as const;
}
