import React from 'react';
import { Navigate } from 'react-router-dom';

export interface IProtectedRouteProps {
   permission: () => boolean;
   subRoute: JSX.Element;
}

export default function ProtectedRoute(
   props: IProtectedRouteProps
): JSX.Element {
   if (!props.permission()) {
      return <Navigate to="/postlogin" replace />;
   }

   return props.subRoute;
}
