// If a MSAL_REDIRECT_URI is not provided via env var, we'll use the current location of the spa.
const documentBaseUri = `${document.location.protocol}//${document.location.host}`;

export default {
   appInsights: {
      connectionString: ensureVar(
         'APPLICATIONINSIGHTS_CONNECTION_STRING',
         process.env.APPLICATIONINSIGHTS_CONNECTION_STRING
      ),
   },
   auth: {
      clientId: ensureVar('MSAL_CLIENT_ID', process.env.MSAL_CLIENT_ID),
      authorityDomain: ensureVar(
         'MSAL_AUTHORITY_DOMAIN',
         process.env.MSAL_AUTHORITY_DOMAIN
      ),
      authorities: {
         signUpSignIn: {
            authority: ensureVar(
               'MSAL_AUTHORITY_SUSI',
               process.env.MSAL_AUTHORITY_SUSI
            ),
         },
      },
      scopes: {
         user: ensureVar('MSAL_SCOPES_USER', process.env.MSAL_SCOPES_USER),
         admin: ensureVar('MSAL_SCOPES_ADMIN', process.env.MSAL_SCOPES_ADMIN),
      },
      redirectUri: ensureVar(
         'MSAL_REDIRECT_URI',
         process.env.MSAL_REDIRECT_URI,
         documentBaseUri
      ),
   },
   intercom: {
      appId: ensureVar('INTERCOM_APP_ID', process.env.INTERCOM_APP_ID),
   },
};

function ensureVar(varName: string, varValue?: string, defaultValue?: string) {
   if (!varValue || varValue?.length == 0) {
      varValue = defaultValue;
   }
   if (!varValue) {
      throw new Error(`Missing value for env var '${varName}'`);
   }
   return varValue;
}
