import React, { useEffect } from 'react';
import {
   useRecoilRefresher_UNSTABLE,
   useRecoilState,
   useRecoilValue,
} from 'recoil';
import api from '../../lib/api';
import { ICustomer, ICustomerRecent } from '../../lib/types';
import { affiliateAtom } from '../../recoil/affiliate';
import { customersArchiveAtom } from '../../recoil/customer-archive';
import {
   customerRecentSelector,
   reloadRecentCustomers,
} from '../../recoil/customer-recent';
import {
   customersSearchQueryAtom,
   searchedCustomersSelector,
} from '../../recoil/customer-search';
import { customersAtom } from '../../recoil/customers';
import { filteredSortedCustomersSelector } from '../../recoil/customers-sort-filter';
import CardCustomer from '../card-customer/card-customer';
import CheckboxText from '../checkbox-text/checkbox-text';
import InviteButton from '../invite-button/invite-button';
import Search, { ISearchProps } from '../search/search';
import SortFilter from '../sort-filter/sort-filter';
import './affiliate-attorney.scss';

export default function AffiliateAttorney(): JSX.Element {
   const filteredSortedList: ICustomer[] = useRecoilValue(
      filteredSortedCustomersSelector
   );
   const customers = useRecoilValue(customersAtom);
   const affiliate = useRecoilValue(affiliateAtom);

   const customerRecents: ICustomerRecent[] = useRecoilValue(
      customerRecentSelector(affiliate?.id)
   );
   const customerRecentRefresh = useRecoilRefresher_UNSTABLE(
      customerRecentSelector(affiliate?.id)
   );
   const [reloadRecent, setReloadRecent] = useRecoilState(
      reloadRecentCustomers
   );

   useEffect(() => {
      if (reloadRecent) {
         customerRecentRefresh();
         setReloadRecent(false);
      }
   }, []);

   const searchProps: ISearchProps = {
      placeholderPrimary: 'for an Active Client ',
      placeholderSecondary: 'by name or customer ID',
      searchQueryAtom: customersSearchQueryAtom,
      searchResultSelector: searchedCustomersSelector,
      resultPageLink: 'client-details',
      searchById: true,
      customerRecents: customerRecents,
      disabled: false,
   };

   return (
      <div className="affiliate-attorney">
         <Search
            placeholderPrimary={searchProps.placeholderPrimary}
            placeholderSecondary={searchProps.placeholderSecondary}
            searchQueryAtom={searchProps.searchQueryAtom}
            searchResultSelector={searchProps.searchResultSelector}
            searchById={searchProps.searchById}
            resultPageLink={searchProps.resultPageLink}
            customerRecents={searchProps.customerRecents}
            customers={customers}
            disabled={false}
         />
         <SortFilter disabled={false} />

         <div className="content-wrapper">
            <div className="attorney-content">
               <div className="attorney-view-header">
                  <InviteButton
                     inviteTarget="Client"
                     headerText="Invite a New Client"
                     bodyText="Inviting a new client will send a release for information (ROI) form to your clients email. Once they sign the ROI they will be added to your client list."
                     confirmText="Send ROI Request"
                     successTextPre="ROI sent to "
                     successTextPost=" email. Once they have signed, they will be added to your client list and tagged with “New Client”. "
                     inviteFunction={api.postInviteCustomer}
                  />
                  <CheckboxText
                     text="Show archive"
                     checkboxState={customersArchiveAtom}
                  />
               </div>
               {filteredSortedList.map((c) => (
                  <CardCustomer key={c.id} customer={c} />
               ))}
            </div>
         </div>
      </div>
   );
}
