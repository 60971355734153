import { useMsal } from '@azure/msal-react';
import React from 'react';
import appConfig from '../lib/app-config';

export type LoginInButtonProps = {
   loginType: 'popup' | 'redirect';
};

// This is very rudimentary for now.
export function LoginButton({ loginType }: LoginInButtonProps) {
   const { instance: adInstance } = useMsal();
   const loginRequest = {
      scopes: [appConfig.auth.scopes.user],
   };
   const onLogin = () => {
      if (loginType === 'popup') {
         adInstance
            .loginPopup(loginRequest)
            .then((r) => {
               adInstance.setActiveAccount(r.account);
            })
            .catch((e) => {
               // TODO: error handling
               console.log(e);
            });
      } else if (loginType === 'redirect') {
         adInstance.loginRedirect(loginRequest).catch((e) => {
            // TODO: error handling
            console.log(e);
         });
      }
   };

   // TODO: make me look nicer
   return <input type="button" onClick={onLogin} value="Sign In" />;
}
