import { atom, selector } from 'recoil';
import api from '../lib/api';
import { ICustomer } from '../lib/types';
import { affiliateAtom } from './affiliate';

export const customersSelector = selector({
   key: 'customersSelector',
   get: async ({ get }): Promise<ICustomer[]> => {
      const affiliate = get(affiliateAtom);
      if (affiliate == null) return [];
      const customers: ICustomer[] | null = await api.getCustomers(
         affiliate.id
      );
      if (customers == null) {
         return [];
      }

      const today = new Date();
      for (const customer of customers) {
         customer.newCustomer =
            today.getTime() - Date.parse(customer.contactCreateUtc) <
            1000 * 60 * 60 * 24 * 30;

         customer.recentViolation =
            today.getTime() - Date.parse(customer.mostRecentViolationUtc) <
            1000 * 60 * 60 * 24 * 30;

         customer.calibrationOverdue = customer.deviceStatus == 'calibration';
         customer.alert = customer.complianceStatus == 'alert';
         customer.name = customer.firstName + ' ' + customer.lastName;
      }
      return customers;
   },
});

export const customersAtom = atom<ICustomer[]>({
   key: 'CustomerList',
   default: customersSelector,
});
