import React from 'react';
import './clear-button.scss';

export interface IClearButtonProps {
   action: () => void;
}

export default function ClearButton(props: IClearButtonProps): JSX.Element {
   const clearSvg = new URL('/public/images/clear.svg', import.meta.url);
   return (
      <button className="clear-button" onClick={() => props.action()}>
         <img src={clearSvg.toString()} />
      </button>
   );
}
