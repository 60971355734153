import { atom, selector } from 'recoil';
import { Filters, ICustomer, Sort, SortDirection } from '../lib/types';
import { customersArchiveAtom } from './customer-archive';
import { customersAtom } from './customers';

// currently applied filter
export const customersFilterAtom = atom<Filters>({
   key: 'CustomerListFilter',
   default: Filters.all,
});

// currently applied sort
export const customersSortAtom = atom<Sort>({
   key: 'CustomerListSort',
   default: Sort.default,
});

// currently applied sort direction (ascending / descending)
export const sortDirectionAtom = atom<SortDirection>({
   key: 'SortDirection',
   default: SortDirection.ascending,
});

export const archivedCustomersSelector = selector({
   key: 'ArchivedCustomerList',
   get: ({ get }): ICustomer[] => {
      const showArchive = get(customersArchiveAtom);
      const customers = get(customersAtom);

      return customers.filter((customer) => !customer.archived || showArchive);
   },
});

// filters customers list based on customersFilterAtom, customersArchiveAtom
export const filteredCustomersSelector = selector({
   key: 'FilteredCustomerList',
   get: ({ get }): ICustomer[] => {
      const filter = get(customersFilterAtom);
      const customers = get(archivedCustomersSelector);

      switch (filter) {
         case Filters.newClients:
            return customers.filter((item) => item.newCustomer);
         case Filters.recentViolations:
            return customers.filter((item) => item.recentViolation);
         case Filters.alerts:
            return customers.filter((item) => item.alert);
         case Filters.overdueCalibrations:
            return customers.filter((item) => item.calibrationOverdue);
         default:
            return customers;
      }
   },
});

// sorts filtered customers list based on customerSortAtom and customerSortDirectionAtom
export const filteredSortedCustomersSelector = selector({
   key: 'FilteredSortedCustomerList',
   get: ({ get }): ICustomer[] => {
      const sort = get(customersSortAtom);
      const customers = get(filteredCustomersSelector);
      const direction = get(sortDirectionAtom);

      let arrayForSort = [...customers];

      switch (sort) {
         case Sort.firstName:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.firstName.localeCompare(b.firstName)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         case Sort.lastName:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.lastName.localeCompare(b.lastName)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         case Sort.email:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.email.localeCompare(b.email)
            );
            return arrayForSort;

         case Sort.mostRecentViolation:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.mostRecentViolationUtc.localeCompare(b.mostRecentViolationUtc)
            );
            return arrayForSort;

         case Sort.numberOfViolations:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.numberOfViolations.localeCompare(b.numberOfViolations)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         case Sort.nextCalibrationDate:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.nextCalibrationDateUtc.localeCompare(b.nextCalibrationDateUtc)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         case Sort.leaseDate:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.leaseSignedUtc.localeCompare(b.leaseSignedUtc)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         case Sort.removalDate:
            arrayForSort = arrayForSort.sort((a, b) =>
               a.removalDateUtc.localeCompare(b.removalDateUtc)
            );
            return direction == SortDirection.ascending
               ? arrayForSort
               : arrayForSort.reverse();

         default:
            return customers;
      }
   },
});
