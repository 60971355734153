import React, { useEffect, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import './calendar.scss';

import { DateRange, DayPicker } from 'react-day-picker';
import { useRecoilState } from 'recoil';
import { dateRangeFilterAtom } from '../../recoil/document-date-filter';

const pastMonth = new Date();

export default function App() {
   const [range, setRange] = useRecoilState<DateRange | undefined>(
      dateRangeFilterAtom
   );
   const [dayPickerClass, setDayPickerClass] = useState('hidden-day-picker');

   function toggleCalendar(): void {
      dayPickerClass == ''
         ? setDayPickerClass('hidden-day-picker')
         : setDayPickerClass('');
   }

   const calendarRef = useRef(null);
   detectClick(calendarRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'calendar' &&
               event.target.className !=
                  'calendar-date body-standard-regular' &&
               event.target.className != 'calendar-icon' &&
               event.target.className != 'calendar calendar-active'
            ) {
               setDayPickerClass('hidden-day-picker');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <div
         className={`calendar ${dayPickerClass == '' ? 'calendar-active' : ''}`}
         onClick={toggleCalendar}
      >
         {range && range.from && range.to && (
            <div className="calendar-date body-standard-regular">
               {range.from.getUTCMonth() +
                  1 +
                  '/' +
                  range.from.getUTCDate() +
                  '/' +
                  (range.from.getUTCFullYear() % 100)}
               &nbsp;-&nbsp;
               {range.to.getUTCMonth() +
                  1 +
                  '/' +
                  range.to.getUTCDate() +
                  '/' +
                  (range.to.getUTCFullYear() % 100)}
            </div>
         )}
         <div className="calendar-icon"></div>
         <div
            className="day-picker-wrapper"
            onClick={(e) => e.stopPropagation()}
            ref={calendarRef}
         >
            <DayPicker
               id="daypicker"
               mode="range"
               className={dayPickerClass}
               defaultMonth={pastMonth}
               selected={range}
               onSelect={setRange}
               captionLayout="dropdown-buttons"
               showOutsideDays
            />
         </div>
      </div>
   );
}
