import React from 'react';
import { ICustomerDocument } from '../../lib/types';
import DownloadButton from '../download-button/download-button';
import './document-card.scss';

export interface IDocumentCardProps {
   customerDocument: ICustomerDocument;
}

export default function DocumentCard(props: IDocumentCardProps): JSX.Element {
   const date: Date = new Date(props.customerDocument.createdUtc);
   const month: number = date.getUTCMonth() + 1;
   const day: number = date.getUTCDate();
   const year: number = date.getUTCFullYear() % 100;

   return (
      <div className="document-card">
         <div className="document-text">
            <div className="body-standard-semibold document-summary">
               {props.customerDocument.title}
            </div>
            <div className="body-standard-regular document-date">
               Uploaded {month + '/' + day + '/' + year}
            </div>
            <div className="body-standard-regular document-type">
               {props.customerDocument.type}
            </div>
         </div>
         <DownloadButton doc={props.customerDocument} inverted={false} />
      </div>
   );
}
