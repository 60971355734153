import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { App } from './app';

const container = document.getElementById('app');
if (container) {
   const root = createRoot(container);
   root.render(
      <RecoilRoot>
         <React.Suspense>
            <App />
         </React.Suspense>
      </RecoilRoot>
   );
}
