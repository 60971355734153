import React from 'react';
import { useNavigate } from 'react-router-dom';
import './back-button.scss';

export default function BackButton(): JSX.Element {
   const navigate = useNavigate();

   return (
      <button className="back-button" onClick={() => navigate(-1)}>
         <div className="back-arrow"></div>
         <div className="body-large-semibold">Back</div>
      </button>
   );
}
