import React from 'react';
import './card-badge.scss';

export interface ICardBadge {
   type: string;
}

export default function CardBadge(props: ICardBadge): JSX.Element {
   let text = '';
   if (props.type == 'new-client') {
      text = 'new client';
   } else if (props.type == 'archive') {
      text = 'archived';
   }
   if (props.type == 'calibration-overdue') {
      text = 'overdue calibration';
   }
   if (props.type == 'alert-device') {
      text = 'alert: device';
   }
   if (props.type == 'recent-violation') {
      text = 'recent violation';
   }
   return (
      <div className={`card-badge ${props.type}`}>
         <div className="badge-icon"></div>
         <div className="label-xsmall-allcaps">{text}</div>
      </div>
   );
}
