import React, { useEffect, useRef } from 'react';
import CopyButton from '../copy-button/copy-button';
import './contact-button.scss';

export interface IContactButtonProps {
   disabled: boolean;
   contact: string;
}

export default function ContactButton(props: IContactButtonProps): JSX.Element {
   const [checked, setChecked] = React.useState(false);
   const [dropdownClass, setdropdownClass] = React.useState('hidden');

   const handleChange = (): void => {
      setChecked(!checked);
      checked ? setdropdownClass('hidden') : setdropdownClass('');
   };

   const contactRef = useRef(null);
   detectClick(contactRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event) {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className != 'contact-button-icon' &&
               event.target.className != 'contact-button' &&
               event.target.className != 'contact-button contact-checked ' &&
               event.target.className !=
                  'contact-button-dropdown-number body-standard-regular' &&
               event.target.className != 'contact-button-dropdown'
            ) {
               setChecked(false);
               setdropdownClass('hidden');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <label
         className={`contact-button ${checked ? 'contact-checked' : ''} ${
            props.disabled ? 'contact-disabled' : ''
         }`}
      >
         <input
            className="contact-checkbox"
            type="checkbox"
            onChange={handleChange}
            disabled={props.disabled}
            checked={checked}
         />
         <div className="contact-button-icon"></div>
         <div
            ref={contactRef}
            onClick={(e) => e.preventDefault()}
            className={`contact-button-dropdown ${dropdownClass}`}
         >
            <div className="contact-button-dropdown-number body-standard-regular">
               {!props.contact || props.contact.length <= 1
                  ? 'N/A'
                  : props.contact}
            </div>
            <CopyButton copyText={props.contact} />
         </div>
      </label>
   );
}
