import React from 'react';
import './welcome-content.scss';

export default function WelcomeContent(): JSX.Element {
   return (
      <div className="welcome-content">
         <div className="body-large-semibold primary-brand-orange">
            Welcome to the NEW CSTDash™!
         </div>
         <div className="large-type">
            Get Easy Access to Your Clients’ Service and Device Data
         </div>
         <div className="body-large-light">
            CSTDash™ is your one stop for client information for Intoxalock
            Ignition Interlock®, Breathe Easy Insurance® and New Directions
            Assessments®.
         </div>
      </div>
   );
}
