import { atom, selector } from 'recoil';
import api from '../lib/api';
import { IAffiliate } from '../lib/types';
import { loadAtom } from './load';

export const affiliateSelector = selector({
   key: 'affiliateSelector',
   get: async ({ get }): Promise<IAffiliate | null> => {
      const load = get(loadAtom);
      return load == null || load.affiliateId == null
         ? null
         : await api.getAffiliate(load.affiliateId);
   },
});

export const affiliateAtom = atom<IAffiliate | null>({
   key: 'Affiliate',
   default: affiliateSelector,
});
