import React from 'react';
import { IAffiliateInvite, ICustomerInvite, IInvite } from '../../lib/types';
import ClearButton from '../clear-button/clear-button';
import ModalTextbox from '../modal-textbox/modal-textbox';
import PrimaryButton from '../primary-button/primary-button';
import ToastMessage from '../toast-message/toast-message';
import './invite-modal.scss';

export interface IInviteModalProps {
   inviteTarget: string;
   headerText: string;
   bodyText: string;
   confirmText: string;
   successTextPre: string;
   successTextPost: string;
   modalClass: string;
   setModalClass: React.Dispatch<React.SetStateAction<string>>;
   submitFunction: (
      invite: IInvite
   ) => Promise<IAffiliateInvite | null> | Promise<ICustomerInvite | null>;
}

export default function InviteModal(props: IInviteModalProps): JSX.Element {
   const successCheckSvg = new URL(
      '/public/images/success-check.svg',
      import.meta.url
   );
   const [inviteFirstName, setInviteFirstName] = React.useState('');
   const [inviteLastName, setInviteLastName] = React.useState('');
   const [inviteEmail, setInviteEmail] = React.useState('');
   const [submitted, setSubmitted] = React.useState(false);
   const [toastClass, setToastClass] = React.useState('toast-hidden');
   const [toastbodyText, setToastBodyText] = React.useState('example');

   const submit = (): void => {
      if (
         inviteFirstName.length == 0 ||
         inviteLastName.length == 0 ||
         inviteEmail.length == 0
      ) {
         setToastClass('');
         setToastBodyText('All fields are required');
      } else if (
         !inviteEmail
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
      ) {
         setToastClass('');
         setToastBodyText('Invalid email address');
      } else {
         setSubmitted(true);
         const invite: IInvite = {
            firstName: inviteFirstName,
            lastName: inviteLastName,
            email: inviteEmail,
         };
         props.submitFunction(invite);
      }
   };

   const hideModal = (): void => {
      setSubmitted(false);
      setInviteFirstName('');
      setInviteLastName('');
      setInviteEmail('');
      props.setModalClass('invite-modal-hidden');
      setToastClass('toast-hidden');
   };

   return (
      <div className={`invite-modal ${props.modalClass}`}>
         {!submitted ? (
            <div className="invite-modal-wrapper">
               <div className="invite-modal-nav-wrapper">
                  <div
                     className="invite-modal-back body-standard-semibold"
                     onClick={() => props.setModalClass('modal-hidden')}
                  >
                     <div className="back-arrow"></div>
                     Back
                  </div>
                  <ClearButton action={hideModal} />
               </div>
               <div className="invite-modal-header h1-headline-regular">
                  {props.headerText}
               </div>
               <div className="invite-modal-body body-standard-regular">
                  {props.bodyText}
               </div>
               <ModalTextbox
                  labelText={props.inviteTarget + ' first name'}
                  textState={inviteFirstName}
                  maxLength={150}
                  setTextState={setInviteFirstName}
               />
               <ModalTextbox
                  labelText={props.inviteTarget + ' last name'}
                  textState={inviteLastName}
                  maxLength={150}
                  setTextState={setInviteLastName}
               />
               <ModalTextbox
                  labelText={props.inviteTarget + ' email'}
                  textState={inviteEmail}
                  maxLength={150}
                  setTextState={setInviteEmail}
               />
               <PrimaryButton action={submit} text={props.confirmText} />
               <ToastMessage
                  type="error"
                  headerText="Error"
                  bodyText={toastbodyText}
                  toastClass={toastClass}
                  setToastClass={setToastClass}
               />
            </div>
         ) : (
            <div className="invite-modal-wrapper invite-modal-success">
               <div className="invite-modal-nav-wrapper">
                  <div className="invite-modal-back body-standard-semibold"></div>
                  <ClearButton action={hideModal} />
               </div>
               <div className="invite-modal-success-header h1-headline-regular">
                  <img src={successCheckSvg.toString()} />
                  Success!
               </div>
               <div className="invite-modal-success-text body-standard-regular">
                  {props.successTextPre}
                  <span className="invite-success-email body-standard-semibold">
                     {inviteFirstName}&apos;s
                  </span>
                  {props.successTextPost}
               </div>
            </div>
         )}
      </div>
   );
}
