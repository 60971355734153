import React from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { Filters } from '../../lib/types';
import {
   archivedCustomersSelector,
   customersFilterAtom,
} from '../../recoil/customers-sort-filter';
import './quick-filter.scss';

export interface IQuickFilterProps {
   text: string;
   disabled: boolean;
}

export default function QuickFilter(props: IQuickFilterProps): JSX.Element {
   const [filter, setFilter]: [Filters, SetterOrUpdater<Filters>] =
      useRecoilState(customersFilterAtom);

   const handleChange = (newFilter): void => {
      newFilter == filter ? setFilter(Filters.all) : setFilter(newFilter);
   };

   const countMembers = (text: string): number => {
      if (props.disabled) {
         return 0;
      }
      const customers = useRecoilValue(archivedCustomersSelector);
      switch (text) {
         case Filters.newClients:
            return customers.filter((item) => item.newCustomer).length;
         case Filters.recentViolations:
            return customers.filter((item) => item.recentViolation).length;
         case Filters.alerts:
            return customers.filter((item) => item.alert).length;
         case Filters.overdueCalibrations:
            return customers.filter((item) => item.calibrationOverdue).length;
         default:
            return customers.length;
      }
   };

   return (
      <label
         className={`quick-filter ${
            !props.disabled ? 'quick-filter-enabled' : ''
         }  ${
            !props.disabled && filter == props.text
               ? 'quick-filter-selected'
               : ''
         }`}
      >
         <div className="body-standard-regular">{props.text}</div>
         <div className="quick-filter-count">
            <div className="label-xsmall">{countMembers(props.text)}</div>
         </div>
         <input
            className="checkbox"
            type="checkbox"
            onChange={() => handleChange(props.text)}
            disabled={props.disabled}
            checked={filter == props.text}
         />
      </label>
   );
}
