import React from 'react';
import './welcome-logos.scss';

export default function WelcomeLogos(): JSX.Element {
   const cstLogoGraySvg = new URL(
      '/public/images/cst-logo-gray.png',
      import.meta.url
   );
   const intoxalockLogoGraySvg = new URL(
      '/public/images/intoxalock-logo-gray.png',
      import.meta.url
   );
   const breatheEasyLogoGraySvg = new URL(
      '/public/images/breathe-easy-logo-gray.png',
      import.meta.url
   );
   const newDirectionsAssessmentsLogoGraySvg = new URL(
      '/public/images/new-directions-assessments-logo-gray.png',
      import.meta.url
   );

   return (
      <div className="welcome-logos">
         <div className="welcome-logos-wrapper scroll-wrapper-primary">
            <img
               className="welcome-logo cst-logo"
               src={cstLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo intoxalock-logo"
               src={intoxalockLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo breathe-easy-logo"
               src={breatheEasyLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo new-directions-logo"
               src={newDirectionsAssessmentsLogoGraySvg.toString()}
            />
         </div>
         <div className="welcome-logos-wrapper scroll-wrapper-secondary">
            <img
               className="welcome-logo cst-logo"
               src={cstLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo intoxalock-logo"
               src={intoxalockLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo breathe-easy-logo"
               src={breatheEasyLogoGraySvg.toString()}
            />
            <img
               className="welcome-logo new-directions-logo"
               src={newDirectionsAssessmentsLogoGraySvg.toString()}
            />
         </div>
      </div>
   );
}
