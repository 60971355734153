import { SerializableParam, atomFamily, selectorFamily } from 'recoil';
import api from '../lib/api';
import { ICustomerReportingState } from '../lib/types';
import { affiliateAtom } from './affiliate';

export const customerReportingStatesAtomFamily = atomFamily<
   ICustomerReportingState[],
   SerializableParam
>({
   key: 'customerReportingStates',
   default: selectorFamily({
      key: 'customerReportingStatesSelector',
      get:
         (customerId) =>
         async ({ get }) => {
            const affiliate = get(affiliateAtom);
            if (affiliate == null) {
               return [];
            }
            const customerReportingStates = await api.getCustomerReportingStates(
               affiliate.id,
                  Number(customerId)
            );

            if (customerReportingStates == null) {
               return [];
            }
            return customerReportingStates;
         },
   }),
});
