import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AffiliateDetailsHeader from '../components/affiliate-details-header/affiliate-details-header';
import AppFooter from '../components/app-footer/app-footer';
import AppHeader from '../components/app-header/app-header';
import BackButton from '../components/back-button/back-button';
import api from '../lib/api';
import { getClaimEmail } from '../lib/authorization';
import {
   IAffiliate,
   IAffiliateImpersonation,
   IAffiliateRecent,
   ICustomer,
} from '../lib/types';
import {
   adminAffiliateAtomFamily,
   adminAffiliateCustomersAtomFamily,
} from '../recoil/admin';
import { reloadRecentAffiliates } from '../recoil/admin-affiliate-recent';
import { adminImpersonateCookie } from '../recoil/admin-impersonate';
import './affiliate-details.scss';

export default function AffiliateDetailsPage(): JSX.Element {
   const inProgressSvg = new URL(
      '/public/images/in-progress.svg',
      import.meta.url
   );
   const { id } = useParams();
   const affiliate: IAffiliate | null = useRecoilValue(
      adminAffiliateAtomFamily(id)
   );
   if (!affiliate) {
      return <div></div>;
   }
   const affiliateCustomers: ICustomer[] = useRecoilValue(
      adminAffiliateCustomersAtomFamily(affiliate.id)
   );

   const setReloadRecent = useSetRecoilState(reloadRecentAffiliates);

   const AffiliateRecent: IAffiliateRecent = {
      affiliateId: affiliate.id,
      adminEmail: getClaimEmail(),
   };
   useEffect(() => {
      setReloadRecent(true);
      api.postRecentAffiliate(AffiliateRecent);
   }, []);

   const cookie: IAffiliateImpersonation | null = useRecoilValue(
      adminImpersonateCookie
   );
   if (cookie) {
      Cookies.remove('x-affdash-actor');
      window.location.reload();
   }

   return (
      <div className="affiliate-details">
         <div className="app-body">
            <AppHeader overrideName={false} />
            <BackButton />
            <div className="affiliate-details-wrapper-row">
               <div className="affiliate-details-wrapper-column">
                  <AffiliateDetailsHeader affiliate={affiliate} />
                  {affiliate.vetted && (
                     <div className="client-list-label label-small-allcaps">
                        client list
                     </div>
                  )}
                  {affiliate.vetted ? (
                     affiliateCustomers.map((c) => (
                        <div className="affiliate-customer-item" key={c.id}>
                           <div className="affiliate-customer-item-id label-mono">
                              ID #{c.id}
                           </div>
                           <div className="affiliate-customer-item-name h2-headline">
                              {c.firstName} {c.lastName}
                           </div>
                        </div>
                     ))
                  ) : (
                     <div className="affiliate-details-unverified-background">
                        <img src={inProgressSvg.toString()} />
                        <div className="affiliate-details-unverified-message h2-headline">
                           Verification is in progress
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         <AppFooter />
      </div>
   );
}
