import { atom, selectorFamily } from 'recoil';
import { customerDocumentsAtomFamily } from './customer-documents';
import { customerReportingStatesAtomFamily } from './customer-reporting-states';

// currently applied jurisdiction filter
export const jurisdictionFilterAtom = atom<string>({
   key: 'JurisdictionFilter',
   default: 'All Jurisdictions',
});

export const customerDocumentJurisdictionSelector = selectorFamily({
   key: 'CustomerDocumentFilters',
   get:
      (customerId) =>
      ({ get }): string[] => {
         const documents = get(customerDocumentsAtomFamily(customerId));
         const jurisdictions = new Set<string>();

         for (const document of documents) {
            for (const metadata of document.customerDocumentMetadata) {
               if (metadata.type == 'jurisdiction') {
                  jurisdictions.add(metadata.value);
               }
            }
         }
         const jurisdictionArray: string[] = [
            'All Jurisdictions',
            ...Array.from(jurisdictions),
         ];
         return jurisdictionArray;
      },
});

export const customerJurisdictionSelector = selectorFamily({
   key: 'CustomerJurisdictionFilters',
   get:
      (customerId) =>
      ({ get }): string[] => {
         const jurisdictions = new Set<string>();
         const reportingStates = get(
            customerReportingStatesAtomFamily(customerId)
         );

         for (const reportingState of reportingStates) {
            jurisdictions.add(reportingState.stateName);
         }

         const jurisdictionArray: string[] = [
            'All Jurisdictions',
            ...Array.from(jurisdictions),
         ];
         return jurisdictionArray;
      },
});
