import React, { useEffect, useRef } from 'react';
import './copy-button.scss';

export interface ICopyButtonProps {
   copyText: string;
}

export default function CopyButton(props: ICopyButtonProps): JSX.Element {
   const [copied, setCopied] = React.useState(false);
   const [buttonText, setButtonText] = React.useState('Copy');

   const handleCopy = (): void => {
      navigator.clipboard.writeText(props.copyText);
      setCopied(true);
      setButtonText('Copied');
   };

   const copyRef = useRef(null);
   detectClick(copyRef);

   function detectClick(ref): void {
      useEffect(() => {
         function handleClickOutside(event): void {
            if (
               ref.current &&
               !ref.current.contains(event.target) &&
               event.target.className !=
                  'contact-button-dropdown-number body-standard-regular' &&
               event.target.className != 'contact-button-dropdown ' &&
               event.target.className !=
                  'email-button-dropdown-number body-standard-regular' &&
               event.target.className != 'email-button-dropdown ' &&
               event.target.className !=
                  'location-button-dropdown-number body-standard-regular' &&
               event.target.className != 'location-button-dropdown '
            ) {
               setCopied(false);
               setButtonText('Copy');
            }
         }
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
            document.removeEventListener('mousedown', handleClickOutside);
         };
      }, [ref]);
   }

   return (
      <button
         className={`copy-button ${copied && 'copied'}`}
         onClick={handleCopy}
         ref={copyRef}
      >
         <div className="body-small-semibold background-white">
            {buttonText}
         </div>
      </button>
   );
}
