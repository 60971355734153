import {
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
} from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { LoginButton } from '../components/login-button';
import { LogoutButton } from '../components/logout-button';
import appConfig from '../lib/app-config';
import { AuthScope, getJson } from '../lib/http-client';
import { AppInfo } from '../lib/types';

// Useful for testing stuff during development. This will likely go away at some point.
export default function Dev(): JSX.Element {
   return (
      <div>
         <main>
            Developer Page
            <UnauthenticatedTemplate>
               <LoginButton loginType="redirect"></LoginButton>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
               <LogoutButton logoutType="redirect"></LogoutButton>
               <UserClaims></UserClaims>
            </AuthenticatedTemplate>
            <AppInfo></AppInfo>
         </main>
      </div>
   );
}

function UserClaims(): JSX.Element {
   const [claims, setClaims] = useState<Claim[]>();
   useEffect(() => {
      (async () => {
         const claimsJson = await getJson<Claim[]>(
            '/api/dev/user/claims',
            AuthScope.affiliate
         );
         if (claimsJson != null) {
            setClaims(claimsJson);
         }
      })();
   }, []);

   return claims ? (
      <table>
         <th>
            <td>Server Claims</td>
         </th>
         {claims.map((c) => (
            <tr key={c.type}>
               <td>{c.type}</td>
               <td>{c.value}</td>
            </tr>
         ))}
      </table>
   ) : (
      <table />
   );
}

// Displays some context information about the running app.
function AppInfo(): JSX.Element {
   const [appInfo, setAppInfo] = useState<AppInfo>();
   useEffect(() => {
      (async () => {
         const resp: Response = await fetch('/api/app/info');
         if (resp.ok) {
            const appInfoJson: AppInfo = (await resp.json()) as AppInfo;
            setAppInfo(appInfoJson);
         }
      })();
   }, []);

   return appInfo ? (
      <table>
         <th>
            <td>Application Info</td>
         </th>
         <tr>
            <td>Git SHA</td>
            <td>{appInfo.build.gitSha}</td>
         </tr>
         <tr>
            <td>Api Environment</td>
            <td>{appInfo.environmentName}</td>
         </tr>
         <tr>
            <td>Api Version</td>
            <td>{appInfo.build.versionNumber}</td>
         </tr>
         <tr>
            <td>Api Build ID</td>
            <td>{appInfo.build.buildId}</td>
         </tr>
         <tr>
            <td>Api Auth Client ID</td>
            <td>{appInfo.auth.clientId}</td>
         </tr>
         <tr>
            <td>Api Auth Domain</td>
            <td>{appInfo.auth.domain}</td>
         </tr>
         <tr>
            <td>Api Auth Audience</td>
            <td>{appInfo.auth.audience}</td>
         </tr>
         <tr>
            <td>Api Auth Instance</td>
            <td>{appInfo.auth.instance}</td>
         </tr>
         <tr>
            <td>OpenID Client ID</td>
            <td>{appConfig.auth.clientId}</td>
         </tr>
         <tr>
            <td>OpenID Authority</td>
            <td>{appConfig.auth.authorities.signUpSignIn.authority}</td>
         </tr>
         <tr>
            <td>OpenID Authority Domain</td>
            <td>{appConfig.auth.authorityDomain}</td>
         </tr>
         <tr>
            <td>OpenID User scope</td>
            <td>{appConfig.auth.scopes.user}</td>
         </tr>
         <tr>
            <td>OpenID Admin scope</td>
            <td>{appConfig.auth.scopes.admin}</td>
         </tr>
         <tr>
            <td>OpenID Redirect URI</td>
            <td>{appConfig.auth.redirectUri}</td>
         </tr>
      </table>
   ) : (
      <table />
   );
}

export type Claim = {
   issuer?: string;
   originalIssuer?: string;
   type?: string;
   value?: string;
   valueType?: string;
};
